/*!
 * Copyright (C) 2015-2017, Peppermill Inc.
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or without
 * modification, are not permitted.
 *//*
 * PepValidate 3.0.5
 * 
 *		2016-08-30 (3.0.1)
 *			- Removed DATA-URIs for CSP inclusion.
 *
 *		2016-11-30 (3.0.2)
 *			- Added code for checkbox and radio buttons.
 *
 *		2017-01-06 (3.0.3)
 *			- Made inputs/selects/textarea have a greater z-index than their span.text elements so select elements expand on click even when you click on the text.
 *
 *		2017-01-23 (3.0.4)
 *			- Added rules for inline datepickers.
 *
 *		2017-05-30 (3.0.5)
 *			- Fixed <label /> styling for Grammarly.
 */

* {
	-webkit-box-sizing	:	border-box;
	-moz-box-sizing		:	border-box;
	box-sizing			:	border-box;
}

/*START DATA-PROTECT CODE*/
.pepvalidate-v3.protect-data,
.pepvalidate-v3 p .data-protect ~ label,
.pepvalidate-v3 p .data-protect ~ label * {
	-webkit-touch-callout	:	none;
	-webkit-user-select		:	none;
	-khtml-user-select		:	none;
	-moz-user-select		:	none;
	-ms-user-select			:	none;
	user-select				:	none;
}
/*END DATA-PROTECT CODE*/

.pepvalidate-v3 p .data-protect {
	position	:	absolute;
	top			:	0;
	right		:	0;
	bottom		:	0;
	left		:	0;
	z-index		:	20;
}

.pepvalidate-v3 {
	position	:	relative;
	max-width	:	400px;
	margin		:	0 auto;
	min-height	:	0;
	
	-webkit-transition	:	min-height 250ms ease-out;
	transition			:	min-height 250ms ease-out;
}

.pepvalidate-v3.full-width {
	max-width	:	none;
	margin		:	0;
}

.pepvalidate-v3.highlight {
	padding		:	32px;
    border		:	2px solid #d1d2d4;
    background	:	#FFF url(/library/images/global/bg-content.png) top center repeat-x;
}

.pepvalidate-v3 .full-width,
html .pepvalidate-v3.full-width p.full-width {
	clear	:	both;
	padding	:	0 32px;
}

.pepvalidate-v3.left-align {
	margin-left	:	0;
}

.pepvalidate-v3 p.hidden,
.pepvalidate-v3 input.hidden {
	display	:	none;
}

.pepvalidate-v3 h2 {
	padding-top	:	0;
	margin		:	16px;
	clear		:	both;
}

.pepvalidate-v3 h2 ~ h2 {
	padding-top	:	2em;
}

.pepvalidate-v3.full-width.show-steps {
	padding-left	:	64px;
	padding-bottom	:	64px;
}

.pepvalidate-v3.show-steps .content-block:before {
	content				:	'';
	position			:	absolute;
	left				:	-64px;
	top					:	-120px;
	bottom				:	-64px;
	width				:	2px;
	background-color	:	#9e9e9e;
	
	-webkit-transition	:	background-color 250ms ease-in;
	transition			:	background-color 250ms ease-in;
}

.pepvalidate-v3.show-steps .content-block ~ .content-block:before {
	top	:	0px;
}

.pepvalidate-v3.show-steps .content-block[data-step=DONE]:before {
	bottom	:	64px;
}

.pepvalidate-v3.show-steps.all-steps-complete .content-block:before,
.pepvalidate-v3.show-steps .content-step.step-complete + .content-block:before,
.pepvalidate-v3.show-steps .content-block.step-complete:before {
	background-color	:	#3f51b5;
	
	-webkit-transition	:	background-color 250ms ease-out;
	transition			:	background-color 250ms ease-out;
}

.pepvalidate-v3.show-steps.all-steps-complete .content-step.icon[data-step="clear"]:before {
	/* content	:	'done_all'; */
	content				:	'';
	background-position	:	-61px -25px;
}

.pepvalidate-v3.show-steps .content-block[data-step=DONE] ~ .content-block:before {
	display	:	none;
}

.pepvalidate-v3.show-steps .content-step[data-step] {
	position	:	relative;
	width		:	0;
	height		:	0;
}

.pepvalidate-v3.show-steps .content-step[data-step]:before {
	content				:	attr(data-step);
	position			:	absolute;
	top					:	54px;
	left				:	-100px;
	width				:	32px;
	height				:	32px;
	line-height			:	32px;
	vertical-align		:	middle;
	text-align			:	center;
	font-size			:	1.5em;
	background-color	:	#9e9e9e;
	border-radius		:	50%;
	color				:	#FFF;
	border				:	20px solid #FAFAFA;
	z-index				:	10;
	background-image	:	url(/library/images/form/form_sprite.png);
	background-position	:	32px 0;
	background-repeat	:	no-repeat;
	
	-webkit-transition	:	background-color 250ms ease-in, -webkit-transform 250ms ease-in;
	transition			:	background-color 250ms ease-in, transform 250ms ease-in;
}

.pepvalidate-v3.show-steps .content-step[data-step][data-step-desc]:after {
	content				:	'';
	position			:	absolute;
	top					:	116px;
	left				:	-114px;
	width				:	100px;
	text-align			:	center;
	color				:	#000;
	background-color	:	#FAFAFA;
	border				:	20px solid #FAFAFA;
	border-width		:	0 0 20px 0;
	z-index				:	20;
	
	-webkit-transition	:	color 250ms ease-in;
	transition			:	color 250ms ease-in;
}

.pepvalidate-v3.show-steps .content-step.step-complete[data-step]:before {
	background-color	:	#3f51b5;
	/* font-family			:	'Material Icons'; */
	/* content				:	'done'; */
	background-position	:	-59px 8px;
	content				:	'';
	
	-webkit-transform	:	rotateY(360deg);
	transform			:	rotateY(360deg);
	
	-webkit-transition	:	background-color 250ms ease-out, -webkit-transform 250ms ease-out;
	transition			:	background-color 250ms ease-out, transform 250ms ease-out;
}

.pepvalidate-v3.show-steps .content-step.step-complete[data-step][data-step-desc]:after {
	color	:	#3f51b5;
	
	-webkit-transition	:	color 250ms ease-out;
	transition			:	color 250ms ease-out;
}

.pepvalidate-v3.show-steps .content-step.icon[data-step]:before {
	font-family	:	'Material Icons';
}

.pepvalidate-v3.loading,
.pepvalidate-v3.loading a {
	cursor	:	wait;
}

.pepvalidate-v3 p {
	position	:	relative;
	margin		:	15px 0;
}

.pepvalidate-v3.full-width p {
	width	:	50%;
	float	:	left;
}

.pepvalidate-v3.full-width td > p,
html .pepvalidate-v3.full-width td > p:nth-child(n) {
	width	:	100%;
	margin	:	0;
	padding	:	0;
}

html .pepvalidate-v3.full-width p.full-width {
	width			:	100%;
	margin-right	:	0;
	float			:	none;
}

html .pepvalidate-v3 p.textarea {
	padding-top	:	70px !important;
}

.pepvalidate-v3.full-width p:nth-of-type(2n + 1) {
	width			:	47%;
	margin-right	:	3%;
	padding-left	:	32px;
}

.ui-mobile .pepvalidate-v3.full-width p {
	width	:	100%;
	float	:	none;
}

.pepvalidate-v3.loading > p,
.pepvalidate-v3.loading > h2 {
	-webkit-transition	:	opacity 250ms ease-out;
	transition			:	opacity 250ms ease-out;
}

.pepvalidate-v3.loading > p,
.pepvalidate-v3.loading > h2,
.pepvalidate-v3.overlay-active > p,
.pepvalidate-v3.overlay-active > h2 {
	opacity	:	0.35;
}

.pepvalidate-v3.full-width p:nth-of-type(2n + 1) {
	margin-right	:	0;
	width			:	50%;
	padding-right	:	16px;
}

.ui-mobile .pepvalidate-v3.full-width p:nth-of-type(2n + 1) {
	width			:	100%;
	padding-left	:	32px;
	padding-right	:	32px;
}

.pepvalidate-v3.full-width p:nth-of-type(2n) {
	padding-left	:	16px;
	padding-right	:	32px;
}

.ui-mobile .pepvalidate-v3.full-width p:nth-of-type(2n) {
	padding-left	:	32px;
	padding-right	:	32px;
}

.pepvalidate-v3 .divider {
	text-align	:	center;
	margin		:	15px 0;
}

.pepvalidate-v3 .divider:before,
.pepvalidate-v3 .divider:after {
	position	:	absolute;
	content		:	"";
	width		:	40%;
	height		:	2px;
	top			:	50%;
	left		:	0;
	margin		:	-1px 0 0 0;
	background	:	#CCC;
}

.pepvalidate-v3 .divider:after {
	left	:	60%;
}

.pepvalidate-v3 label {
	position	:	relative;
	display		:	block;
	cursor		:	text;
	height		:	60px;
	overflow	:	initial !important;
}

.pepvalidate-v3 td > label {
	height	:	48px;
}

.pepvalidate-v3 .multiselect label {
	height	:	260px;
}

.pepvalidate-v3 .multiselect.multi-half label {
	height	:	140px;
}

.pepvalidate-v3 .checkbox label,
.pepvalidate-v3 .radio label {
	cursor	:	pointer;
}

.pepvalidate-v3 .checkbox label:before,
.pepvalidate-v3 .checkbox label:after,
.pepvalidate-v3 .radio label:before,
.pepvalidate-v3 .radio label:after {
	content			:	'';
	position		:	absolute;
	top				:	50%;
	left			:	50%;
	width			:	32px;
	height			:	32px;
	margin			:	-16px 0 0 -16px;
	z-index			:	10;
	background		:	transparent url(/library/images/form/form_sprite.png) 0 0 no-repeat;
	
	-webkit-transition	:	opacity 250ms ease-out, visibility 0ms linear 0ms;
	transition			:	opacity 250ms ease-out, visibility 0ms linear 0ms;
}

.pepvalidate-v3 .checkbox label:after,
.pepvalidate-v3 .radio label:after {
	background-position	:	-32px 0;
	z-index				:	20;
	visibility			:	hidden;
	opacity				:	0;
	
	-webkit-transition	:	opacity 250ms ease-out, visibility 0ms linear 250ms;
	transition			:	opacity 250ms ease-out, visibility 0ms linear 250ms;
}

.pepvalidate-v3 .checkbox.checked label:after,
.pepvalidate-v3 .radio.checked label:after {
	visibility	:	visible;
	opacity		:	1;
	
	-webkit-transition	:	opacity 250ms ease-out, visibility 0ms linear 0ms;
	transition			:	opacity 250ms ease-out, visibility 0ms linear 0ms;
}

.pepvalidate-v3 .radio label:before {
	background-position	:	0 -32px;
}

.pepvalidate-v3 .radio label:after {
	background-position	:	-32px -32px;
}

.pepvalidate-v3 .checkbox label input[type=checkbox],
.pepvalidate-v3 .radio label input[type=radio] {
	position	:	absolute;
	left		:	-9999px;
}

.pepvalidate-v3.loading label {
	cursor	:	wait;
}

.pepvalidate-v3 label > .text,
.pepvalidate-v3 label > .note {
	position	:	absolute;
	bottom		:	12px;
	left		:	10px;
	will-change	:	bottom, color;
	color		:	#000;
	z-index		:	1;
	
	-webkit-transition	:	bottom 250ms ease-in 125ms, left 250ms ease-in 125ms, color 250ms ease-in 125ms;
	transition			:	bottom 250ms ease-in 125ms, left 250ms ease-in 125ms, color 250ms ease-in 125ms;
}

.pepvalidate-v3 label > .note {
	font-size	:	0.8em;
}

.pepvalidate-v3 label > .text svg * {
	fill	:	currentColor;
}

.pepvalidate-v3 label > .note {
	bottom	:	44px;
}

.pepvalidate-v3 .active.validated label > .text,
.pepvalidate-v3 .active.validated label > .note,
.pepvalidate-v3 .active.filled label > .text,
.pepvalidate-v3 .active.filled label > .note {
	color	:	#3f51b5;
}

/* .pepvalidate-v3 .checkbox label > .text {
	color		:	currentColor;
	position	:	relative;
	bottom		:	auto;
} */

.pepvalidate-v3 .textarea label > .text,
.pepvalidate-v3 .textarea label > .note {
	bottom	:	82px;
}

.pepvalidate-v3.low-profile label > .text,
.pepvalidate-v3.low-profile label > .note {
	color	:	#000;
	left	:	0;
}

.pepvalidate-v3 label > .text svg {
	margin-bottom	:	-2px;
}

.pepvalidate-v3.low-profile label > .note {
	bottom	:	12px;
}

.pepvalidate-v3 .active label > .text,
.pepvalidate-v3 .active label > .note,
.pepvalidate-v3 .error label > .text,
.pepvalidate-v3 .error label > .note,
.pepvalidate-v3 .split-fields-2 label > .text,
.pepvalidate-v3 .split-fields-2 label > .note {
	left		:	0;
	bottom		:	44px;
	color		:	#000;
	
	-webkit-transition	:	bottom 250ms ease-out, left 250ms ease-out, color 250ms ease-out;
	transition			:	bottom 250ms ease-out, left 250ms ease-out, color 250ms ease-out;
}

.pepvalidate-v3 .active.multiselect label > .text,
.pepvalidate-v3 .active.multiselect label > .note,
.pepvalidate-v3 .error.multiselect label > .text,
.pepvalidate-v3 .error.multiselect label > .note,
.pepvalidate-v3 .split-fields-2.multiselect label > .text,
.pepvalidate-v3 .split-fields-2.multiselect label > .note {
	bottom	:	244px;
}

.pepvalidate-v3 .active.multiselect.multi-half label > .text,
.pepvalidate-v3 .active.multiselect.multi-half label > .note,
.pepvalidate-v3 .error.multiselect.multi-half label > .text,
.pepvalidate-v3 .error.multiselect.multi-half label > .note,
.pepvalidate-v3 .split-fields-2.multiselect.multi-half label > .text,
.pepvalidate-v3 .split-fields-2.multiselect.multi-half label > .note {
	bottom	:	124px;
}

.pepvalidate-v3 .textarea.active label > .text,
.pepvalidate-v3 .textarea.active label > .note,
.pepvalidate-v3 .textarea.error label > .text,
.pepvalidate-v3 .textarea.error label > .note {
	bottom	:	114px;
}

.pepvalidate-v3 label > .note {
	position	:	absolute;
	right		:	0;
	left		:	auto !important;
}

.pepvalidate-v3 .note-content {
	display		:	none;
	max-width	:	100%;
}

.pepvalidate-v3 .note-content img {
	max-width	:	100%;
}

.pepvalidate-v3 .error label > .text,
.pepvalidate-v3 .active.error label > .text {
	color	:	#FFA500;
}

.pepvalidate-v3 label > .text:after {
	position	:	relative;
	content		:	" Here";
	will-change	:	opacity;
	
	-webkit-transition	:	opacity 250ms ease-in 125ms;
	transition			:	opacity 250ms ease-in 125ms;
}

.pepvalidate-v3 .checkbox label .text:after,
.pepvalidate-v3 .radio label .text:after {
	display	:	none;
}

.pepvalidate-v3 .active label > .text:after,
.pepvalidate-v3 .error label > .text:after {
	opacity	:	0;
	
	-webkit-transition	:	opacity 250ms ease-out;
	transition			:	opacity 250ms ease-out;
}

.pepvalidate-v3 label > .text > .material-icons,
.pepvalidate-v3 label > .note > .material-icons,
.pepvalidate-v3 .split-fields-2 label > .text > .material-icons,
.pepvalidate-v3 .split-fields-2 label > .note > .material-icons {
	vertical-align	:	bottom;
	margin-bottom	:	-2px;
}

.pepvalidate-v3 input,
.pepvalidate-v3 select,
.pepvalidate-v3 textarea {
	position		:	absolute;
	bottom			:	3px;
	left			:	0;
	z-index			:	0;
	outline			:	none;
	background		:	#F5F5F5;
	border-color	:	#E0E0E0;
	border-width	:	0 0 2px 0;
	border-style	:	solid;
	height			:	35px;
	width			:	100%;
	margin			:	5px 0 0 0;
	padding			:	0 2%;
	will-change		:	height, border-color, border-width;
	border-radius	:	4px;
	font-size		:	16px;
	
	-webkit-transition	:	height 250ms ease-in 125ms, border-color 250ms 125ms ease-in, border-width 250ms ease-in 125ms;
	transition			:	height 250ms ease-in 125ms, border-color 250ms 125ms ease-in, border-width 250ms ease-in 125ms;
}

.pepvalidate-v3 select {
	width	:	100%;
}

.pepvalidate-v3 .multiselect select {
	height	:	235px;
}

.pepvalidate-v3 .multiselect.multi-half select {
	height	:	115px;
}

.pepvalidate-v3 textarea {
	height	:	100px;
}

.pepvalidate-v3 .split-fields-2 input,
.pepvalidate-v3 .split-fields-2 select,
.pepvalidate-v3 .split-fields-2 textarea {
	width	:	48%;
}

.pepvalidate-v3 .split-fields-2 input + input,
.pepvalidate-v3 .split-fields-2 select + select,
.pepvalidate-v3 .split-fields-2 textarea + textarea {
	left	:	50%;
}

.pepvalidate-v3.low-profile input,
.pepvalidate-v3.low-profile select,
.pepvalidate-v3.low-profile textarea {
	height	:	0;	
}

.pepvalidate-v3.loading input,
.pepvalidate-v3.loading select,
.pepvalidate-v3.loading textarea {
	cursor	:	wait;
}

.pepvalidate-v3 .active input,
.pepvalidate-v3 .error input,
.pepvalidate-v3 .active select,
.pepvalidate-v3 .error select,
.pepvalidate-v3 .active textarea,
.pepvalidate-v3 .error textarea {
	height			:	35px;
	line-height		:	35px;
	border-width	:	0 0 2px 0;
	vertical-align	:	middle;
	border-color	:	#3f51b5;
	
	-webkit-transition	:	height 250ms ease-out, border-color 250ms ease-out, border-width 250ms ease-out;
	transition			:	height 250ms ease-out, border-color 250ms ease-out, border-width 250ms ease-out;
}

.pepvalidate-v3 .active.multiselect select,
.pepvalidate-v3 .error.multiselect select {
	height	:	235px;
}

.pepvalidate-v3 .active.multiselect.multi-half select,
.pepvalidate-v3 .error.multiselect.multi-half select {
	height	:	115px;
}

.pepvalidate-v3 .active textarea,
.pepvalidate-v3 .error textarea {
	height	:	100px;
	resize	:	none;
}

.pepvalidate-v3 .error input,
.pepvalidate-v3 .active.error input,
.pepvalidate-v3 .error select,
.pepvalidate-v3 .active.error select,
.pepvalidate-v3 .error textarea,
.pepvalidate-v3 .active.error textarea {
	border-color	:	#FFA500;
}

.pepvalidate-v3 .validated input,
.pepvalidate-v3 .filled input,
.pepvalidate-v3 .validated select,
.pepvalidate-v3 .filled select,
.pepvalidate-v3 .validated textarea
.pepvalidate-v3 .filled textarea {
	border-color	:	#3f51b5;
}

.pepvalidate-v3 input:hover,
.pepvalidate-v3 .text:hover + input,
.pepvalidate-v3 select:hover,
.pepvalidate-v3 .text:hover + select,
.pepvalidate-v3 textarea:hover,
.pepvalidate-v3 .text:hover + textarea {
	border-color	:	#000;
}

.pepvalidate-v3 .submit-wrapper,
.pepvalidate-v3.loading .submit-wrapper {
	text-align	:	center;
	opacity		:	1;
}

.pepvalidate-v3.loading .submit-wrapper.bounce {
	-webkit-animation	:	pepvalidate-v3-error-bounce 250ms 0ms 2 ease-in-out;
	-moz-animation		:	pepvalidate-v3-error-bounce 250ms 0ms 2 ease-in-out;
	animation			:	pepvalidate-v3-error-bounce 250ms 0ms 2 ease-in-out;
}

.pepvalidate-v3 .submit-wrapper:before {
	position		:	absolute;
	content			:	"";
	top				:	50%;
	left			:	50%;
	width			:	32px;
	height			:	32px;
	margin			:	-21px 0 0 -20px;
	background		:	#000 url(/library/images/global/ajax-loader.gif) center center no-repeat;
	z-index			:	-1;
	opacity			:	0;
	border-radius	:	20px;
	border			:	4px solid #FFF;
	
	-webkit-transition	:	opacity 250ms ease-out;
	transition			:	opacity 250ms ease-out;
}

.pepvalidate-v3.loading .submit-wrapper:before {
	opacity	:	1;
	z-index	:	1;
}

.pepvalidate-v3 input[type=submit],
.pepvalidate-v3 input[type=button] {
	position			:	relative;
	width				:	100%;
	padding				:	0;
	text-align			:	center;
	outline				:	none;
	border				:	none;
	height				:	40px;
	line-height			:	40px;
	vertical-align		:	middle;
	cursor				:	pointer;
	color				:	#FFF;
	border-radius		:	4px;
	background-color	:	#3f51b5;
	text-transform		:	uppercase;
	
	-webkit-transition	:	background-color 125ms ease-out, border-radius 250ms ease-out, width 250ms ease-out, box-shadow 120ms ease-out, color 250ms ease-out;
	transition			:	background-color 125ms ease-out, border-radius 250ms ease-out, width 250ms ease-out, box-shadow 125ms ease-out, color 250ms ease-out;
}

.pepvalidate-v3 input[type=submit]:active,
.pepvalidate-v3 input[type=button]:active {
	background	:	#CCC;
	box-shadow	:	0 2px 4px 2px rgba(0, 0, 0, 0.35);
}

.pepvalidate-v3 input[type=submit]:hover,
.pepvalidate-v3 input[type=button]:hover,
.pepvalidate-v3 input[type=submit]:focus,
.pepvalidate-v3 input[type=button]:focus {
	background-color	:	#3f51b5;
	box-shadow			:	0 2px 4px 0px rgba(0, 0, 0, 0.35);
}

.pepvalidate-v3.loading input[type=submit],
.pepvalidate-v3.loading input[type=button] {
	width			:	40px;
	border-radius	:	20px;
}

.pepvalidate-v3 fieldset {
	margin	:	0;
	padding	:	5px;
}

.pepvalidate-v3 .honey {
	display	:	none;
}


/*START OVERLAY CODE*/
.pepvalidate-v3 .overlay {
	position	:	absolute;
	bottom		:	0;
	right		:	0;
	left		:	0;
	max-height	:	0;
	min-height	:	0;
	overflow	:	hidden;
	background	:	#000;
	background	:	rgba(0, 0, 0, 0.95);
	z-index		:	8;
	padding		:	10px 30px 10px 10px;
	opacity		:	0;
	color		:	#FFF;
	
	-webkit-transition	:	opacity 250ms ease-out, max-height 250ms ease-out, min-height 250ms ease-out, -webkit-box-shadow 250ms ease-out;
	transition			:	opacity 250ms ease-out, max-height 250ms ease-out, min-height 250ms ease-out, box-shadow 250ms ease-out;
}

.pepvalidate-v3.overlay-active .overlay {
	max-height	:	100%;
	min-height	:	50px;
	opacity		:	1;
	
	-webkit-box-shadow	:	0 0 96px rgba(255, 255, 255, 0.35);
	box-shadow			:	0 0 96px rgba(255, 255, 255, 0.35);
}

.pepvalidate-v3 .overlay .close {
	position		:	absolute;
	top				:	0;
	right			:	0;
	width			:	20px;
	height			:	20px;
	line-height		:	20px;
	vertical-align	:	middle;
	text-align		:	center;
	border			:	2px solid #FFF;
	color			:	#FFF;
	font-family		:	TitleFont;
	font-size		:	15px;
	text-decoration	:	none;
	
	-webkit-border-radius	:	50%;
	border-radius			:	50%;
}
/*END OVERLAY CODE*/


/*START ERROR MESSAGE CODE*/
.pepvalidate-v3 .error-msg {
	position		:	absolute;
	bottom			:	-22px;
	left			:	10%;
	color			:	#000;
	background		:	#FBFBFB;
	vertical-align	:	middle;
	white-space		:	nowrap;
	padding			:	6px 8px 4px 8px;
	border			:	1px solid #000;
	cursor			:	default;
	z-index			:	8;
	
	-webkit-border-radius	:	4px;
	border-radius			:	4px;
	
	-webkit-transform	:	scale(0);
	transform			:	scale(0);
	
	-webkit-transition	:	opacity 250ms ease-out, background-color 250ms ease-in, -webkit-transform 250ms ease-out;
	transition			:	opacity 250ms ease-out, background-color 250ms ease-in, transform 250ms ease-out;
	
	opacity			:	0;
	zoom			:	1;
	filter			:	alpha(opacity=0);
}

.no-touchevents .pepvalidate-v3 .error-msg:hover {
	background-color	:	#EEE;
	
	-webkit-transition	:	opacity 250ms ease-out, background-color 250ms ease-out, -webkit-transform 250ms ease-out;
	transition			:	opacity 250ms ease-out, background-color 250ms ease-out, transform 250ms ease-out;
}

.pepvalidate-v3 .error .error-msg {
	-webkit-transform	:	scale(1);
	transform			:	scale(1);
	
	opacity	:	1;
	filter	:	alpha(opacity=100);
	cursor	:	pointer;
}

.pepvalidate-v3 .error.bounce .error-msg {
	-webkit-animation	:	pepvalidate-v3-error-bounce 250ms 0ms 2 ease-in-out;
	animation			:	pepvalidate-v3-error-bounce 250ms 0ms 2 ease-in-out;
}

.pepvalidate-v3 .error-msg .icon {
	vertical-align	:	middle;
	margin-right	:	5px;
	margin-top		:	-3px;
	color			:	#FFA500;
}

.pepvalidate-v3 .error-msg:before,
.pepvalidate-v3 .error-msg:after {
	position		:	absolute;
	content			:	"";
	top				:	-5px;
	left			:	10px;
	width			:	0;
	height			:	0;
	z-index			:	4;
	border-left		:	6px solid transparent;
	border-right	:	6px solid transparent;
	border-bottom	:	6px solid #FBFBFB;
	
	-webkit-transition	:	border-color 250ms ease-in;
	transition			:	border-color 250ms ease-in;
}

.pepvalidate-v3 .error-msg:before {
	top				:	-6px;
	z-index			:	2;
	border-bottom	:	6px solid #000;
}

.no-touchevents .pepvalidate-v3 .error-msg:hover:after {
	border-bottom-color	:	#EEE;
	
	-webkit-transition	:	border-color 250ms ease-out;
	transition			:	border-color 250ms ease-out;
}

@-webkit-keyframes pepvalidate-v3-error-bounce {
	0% {
		-webkit-transform	:	translateX(0);
	}
	25% {
		-webkit-transform	:	translateX(-10px);
	}
	75% {
		-webkit-transform	:	translateX(10px);
	}
}

@-moz-keyframes pepvalidate-v3-error-bounce {
	0% {
		-moz-transform	:	translateX(0);
	}
	25% {
		-moz-transform	:	translateX(-10px);
	}
	75% {
		-moz-transform	:	translateX(10px);
	}
}

@keyframes pepvalidate-v3-error-bounce {
	0% {
		transform	:	translateX(0);
	}
	25% {
		transform	:	translateX(-10px);
	}
	75% {
		transform	:	translateX(10px);
	}
}
/*END ERROR MESSAGE CODE*/











/*START SHOW PASSWORD CODE*/
.pepvalidate-v3 p.show-password-timer:after {
	visibility	:	visible;
	opacity		:	1;
	
	-webkit-transition	:	opacity 250ms ease-out, visibility 0ms linear 0ms;
	transition			:	opacity 250ms ease-out, visibility 0ms linear 0ms;
	
	-webkit-animation	:	password-timer 10000ms forwards linear;
	animation			:	password-timer 10000ms forwards linear;
}

.pepvalidate-v3 p:after {
	content				:	"";
	position			:	absolute;
	bottom				:	3px;
	right				:	32px;
	left				:	32px;
	height				:	6px;
	visibility			:	hidden;
	background-color	:	#3f51b5;
	z-index				:	10;
	opacity				:	0;
	
	-webkit-transition	:	opacity 250ms ease-out, visibility 0ms linear 250ms;
	transition			:	opacity 250ms ease-out, visibility 0ms linear 250ms;
	
	-webkit-transform-origin	:	top left;
	transform-origin			:	top left;
}

.pepvalidate-v3.full-width p:nth-of-type(2n + 1):after {
	right	:	16px;
}

.pepvalidate-v3.full-width p:nth-of-type(2n):after {
	left	:	16px;
}

@-webkit-keyframes password-timer {
	0% {
		opacity				:	0;
		background-color	:	#3f51b5;
		-webkit-transform	:	scaleX(0);
	}
	10% {
		opacity				:	1;
		background-color	:	#3f51b5;
	}
	90% {
		opacity				:	1;
		background-color	:	#FFA500;
	}
	100% {
		opacity				:	0;
		background-color	:	#FFA500;
		-webkit-transform	:	scaleX(1);
	}
}

@keyframes password-timer {
	0% {
		opacity	:	0;
		transform	:	scaleX(0);
	}
	10% {
		opacity	:	1;
	}
	90% {
		opacity	:	1;
	}
	100% {
		opacity	:	0;
		transform	:	scaleX(1);
	}
}
/*END SHOW PASSWORD CODE*/











.pepvalidate-v3 .handle,
.pepvalidate-v3 .help {
	position	:	absolute;
	right		:	8px;
	top			:	26px;
	color		:	#000;
	z-index		:	30;
	
	-webkit-transition	:	color 250ms ease-in;
	transition			:	color 250ms ease-out;
}

.pepvalidate-v3 .handle.hide,
.pepvalidate-v3 .help.hide {
	display	:	none;
}

/* .pepvalidate-v3 .error .handle,
.pepvalidate-v3 .error .help {
	color	:	#FFA500;
} */

.no-touchevents .pepvalidate-v3 .handle:hover,
.no-touchevents .pepvalidate-v3 .help:hover {
	color	:	#3f51b5;
}









/*.inline-datepicker rules*/
.pepvalidate-v3 p.inline-datepicker {
	margin-top	:	32px;
	text-align	:	left;
}

.pepvalidate-v3 p.inline-datepicker .text,
.pepvalidate-v3 p.inline-datepicker .note {
    position	:	relative;
    bottom		:	12px;
}

.pepvalidate-v3 p.inline-datepicker input.date,
.pepvalidate-v3 p.inline-datepicker .handle {
    display	:	none;
}

.pepvalidate-v3 p.inline-datepicker input.date,
.pepvalidate-v3 p.inline-datepicker .handle {
    display	:	none;
}

.pepvalidate-v3 p.inline-datepicker label {
    height	:	auto;
    cursor	:	default;
}

.pepvalidate-v3 p.inline-datepicker .datepicker-inline .datepicker {
	width	:	100%;
}