/*!
 * @copyright (C) 2012-2024, Peppermill Inc. All rights reserved. Redistribution and use in source and binary forms, with or without modification, are not permitted.
 *//*
 * WendoverFun.com Custom CSS V3
 * @changelog
 *  2017-04-21
 *    - Start updating to a light theme.
 *
 *  2017-06-04
 *    - Update font declarations for better fallbacks.
 *    - Move @font rules to head.php.
 *
 *  2021-09-09 (zsahs)
 *    - Add rules for new OPC navigation button/menu.
 *
 *  2021-10-13
 *    - Make body a minimum of 100vh so the footer isn't hidden for pages with short content.
 *
 *  2023-03-22 (jhoward)
 *    - Use position:sticky for #subnav.
 *
 *  2024-07-24 (jhoward)
 *    - Cleanup old -webkit- prefixed properties in main-v3.css.
 *    - Add rules for footer accessibility link in main-v3.css.
 */


* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: BodyFont, Verdana, Geneva, sans-serif;
  font-size: 12px;
}

body {
  background-color: #FFF;
  color: #000;
  min-height: 100vh;
}

html.text-increase,
.text-increase body {
  font-size: 16px;
}

html.scrollbar-hide,
.scrollbar-hide body {
  overflow: hidden;
}

html.scrollbar-hide,
.scrollbar-hide body {
  overflow: hidden;
}

img {
  margin: 0;
  padding: 0;
  border: none;
}

a {
  color: #000;

  transition: color 125ms ease-in;
}

.no-touchevents a:hover {
  color: #651063;

  transition: color 125ms ease-out;
}

.ui-mobile body #container .mobile-hidden {
  display: none;
}

.ui-tablet body #container .tablet-hidden {
  display: none;
}

.ui-desktop body #container .desktop-hidden {
  display: none;
}

.ui-widescreen body #container .widescreen-hidden {
  display: none;
}

.on-property-only {
  display: none;
}

.on-property .on-property-only {
  display: block;
}

.on-property .not-on-property {
  display: none;
}

.system-symbol * {
  fill: currentColor;
  stroke: currentColor;
}

.scroller {
  overflow: auto;
}

html .pepvalidate-v3 .error-msg .icon {
  display: none;
}

#container {
  position: relative;
  height: auto;
  min-height: 100%;
}

#header {
  position: relative;
  /*
	height			: 211px;
	*/
  height: 100px;
  background: #FFF;
  /* background		: #fff url(/library/images/global/header-bg.jpg) no-repeat; */
  background-size: 100% 75%;
  z-index: 30;
}

.ui-mobile #header {
  height: 64px;
  z-index: 24;
}

#header .menu-handle {
  display: none;
  position: absolute;
  top: 8px;
  left: 8px;
  width: 48px;
  height: 48px;
  z-index: 8;
}

.ui-mobile #header .menu-handle {
  display: block;
}

#header .menu-handle svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: opacity, transform;
  fill: #651063;

  transition: opacity 125ms ease-in-out 125ms, transform 250ms ease-in-out;
}

#header .menu-handle .ic_close_24px {
  opacity: 0;

  transform: rotateZ(360deg);
}

#header.menu-open .menu-handle .ic_close_24px {
  opacity: 1;

  transform: none;
}

#header.menu-open .menu-handle .ic_menu_24px {
  opacity: 0;

  transform: rotateZ(-360deg);
}

#header .opc-btn {
  position: relative;
  display: inline-block;
  width: 112px;
  vertical-align: top;
  color: #6f6f6f;
  text-decoration: none;
  padding: 8px 12px 0 32px;
  margin-left: 16px;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
}

html #header .opc-btn.logged-in {
  width: 150px;
  overflow: visible;
}

.ui-mobile #header .opc-btn.logged-in {
  width: 100%;
}

.ui-tablet #header .opc-btn {
  width: 60px;
  height: 30px;
}

.ui-mobile #header .opc-btn {
  width: 100%;
  margin: 0;
  text-align: center;
  border-top: 2px solid #FAFAFA;
}

.ui-mobile #header .opc-btn.logged-in .opc-btn-span,
.ui-mobile #header .opc-btn.logged-in::after {
  display: none !important;
}

.ui-widescreen #header .opc-btn {
  width: 250px;
}

#header .opc-btn:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  background: transparent url('/library/images/global/opc-logo.png') 50% 50% no-repeat;
}

.ui-mobile #header .opc-btn:before {
  display: inline-block;
  position: relative;
  margin-right: 8px;
  top: 4px;
}

#header .opc-btn:after {
  position: absolute;
  content: '';
  top: 11px;
  right: 0;
  width: 0;
  height: 0;
  margin-left: 8px;
  border-top: 8px solid #6f6f6f;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;

  transition: transform 250ms ease-in-out;
}

.ui-mobile #header .opc-btn:after {
  display: none;
}

#header .opc-btn.active:after {
  transition: transform 250ms ease-in-out 125ms;
}

.ui-tablet #header .opc-btn.active:after {
  transform: none;
}

.ui-widescreen #header .opc-btn.active:after {
  transform: translateX(-134px);
}

#header .opc-btn .msg {
  position: absolute;
  top: 25px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 10px;
  color: #651063;
}

#header .opc-btn.logged-in:after {
  display: none;
}

#header .opc-btn.logged-in[data-offer-count]:after {
  content: attr(data-offer-count);
  position: relative;
  display: inline-block;
  top: -4.5px;
  right: 7px;
  width: 24px;
  height: 24px;
  text-align: center;
  vertical-align: middle;
  font-size: 10px;
  line-height: 24px;
  z-index: 3;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.35);
  border: none;
}

#opc-login {
  position: fixed;
  top: 0;
  left: 50%;
  width: 966px;
  height: 350px;
  margin-left: -488px;
  background: #FFF;
  padding: 24px;
  visibility: hidden;
  opacity: 0;
  z-index: 40;
  border-radius: 4px;

  transform: translateY(24px);
}

.loaded #opc-login {
  transition: opacity 250ms ease-in-out, visibility 1ms linear 250ms, transform 250ms ease-in-out;
}

.ui-mobile #opc-login {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin-left: 0;
  z-index: 10;
  overflow: auto;
  border-radius: 0;
}

.ui-tablet #opc-login {
  left: 0;
  width: 100%;
  margin-left: 0;
  overflow: auto;
  border-radius: 0;
}

#opc-login.active {
  visibility: visible;
  opacity: 1;
  z-index: 45;
  transform: none;
  transition: opacity 250ms ease-in-out, visibility 1ms linear, transform 250ms ease-in-out;
}

#opc-login .close {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  z-index: 10;
}

#opc-login .close svg {
  width: 100%;
  height: 100%;
}

#opc-login .logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 33%;
  height: 100%;
  color: #6f6f6f;
  padding: 24px;
}

.ui-mobile #opc-login .logo {
  position: relative;
  width: 100%;
  height: auto;
}

.ui-tablet #opc-login .logo {
  width: 20%;
}

#opc-login .logo svg {
  width: 100%;
  height: 100%;
}

.ui-tablet #opc-login .logo svg {
  width: 100%;
  height: 90%;
}

#opc-login .pepvalidate-v3 {
  position: absolute;
  top: 0;
  left: 33%;
  width: 33%;
  height: 100%;
  max-width: none;
  padding: 24px;
}

.ui-mobile #opc-login .pepvalidate-v3 {
  position: relative;
  left: 0;
  width: 100%;
  height: auto;
}

.ui-tablet #opc-login .pepvalidate-v3 {
  left: 20%;
  width: 50%;
}

#opc-login .login-info {
  position: absolute;
  top: 0;
  left: 66%;
  width: 34%;
  height: 100%;
  padding: 24px;
}

.ui-mobile #opc-login .login-info {
  position: relative;
  left: 0;
  width: 100%;
  height: auto;
}

.ui-tablet #opc-login .login-info {
  left: 70%;
  width: 30%;
}

.wendover-logo {
  position: absolute;
  top: 12px;
  right: 50%;
  width: 472px;
  height: 39px;
  margin: 0 -236px 0 0;
}

.ui-mobile .wendover-logo {
  top: 24px;
  right: 16px;
  width: auto;
  height: auto;
  max-width: 80%;
  max-width: calc(100% - 80px);
  margin: 0;
}

.ui-mobile .nav-grad {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

#nav {
  position: absolute;
  top: 67px;
  left: 0;
  right: 0;
  height: 33px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  background-color: #FFF;
  z-index: 7;
  white-space: nowrap;
  transition: box-shadow 350ms ease-in-out, opacity 250ms ease-in, visibility 1ms linear 250ms, transform 250ms ease-in;
}

.ui-mobile #nav {
  position: fixed;
  top: 64px;
  opacity: 0;
  height: calc(100% - 64px);
  visibility: hidden;
  overflow: auto;
  transform: translateY(24px);
}

.ui-mobile #nav:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 16px;
  z-index: 10;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;White+to+Transparent */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=0); /* IE6-9 */
}

.ui-mobile .menu-open #nav {
  opacity: 1;
  visibility: visible;
  transform: none;
  transition: box-shadow 350ms ease-in-out, opacity 250ms ease-out, visibility 1ms linear, transform 250ms ease-out;
}

html.ui-mobile.ui-transition #nav {
  transition: none;
}

#nav.fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.35);
}

#nav a.main-nav {
  position: relative;
  display: inline-block;
  *display: inline;
  zoom: 1;
  padding: 0 22px;
  height: 33px;
  line-height: 33px;
  vertical-align: middle;
  overflow: hidden;
  color: #651063;
  background-color: transparent;
  text-decoration: none;
  font-family: BodyFont, sans-serif;
  font-size: 13px;
  transition: color 0.375s ease-in-out;
}

.no-touchevents #nav a.main-nav:hover {
  color: #999;
}

.ui-desktop #nav a.main-nav {
  padding: 0 17px;
}

.ui-tablet #nav a.main-nav {
  padding: 0 0.3%;
  margin: 0 0.3%;
}

#nav a.main-nav:after {
  position: absolute;
  content: '';
  left: 5%;
  right: 5%;
  bottom: 4px;
  height: 3px;
  background-color: #DADADA;
  z-index: -1;
}

#nav a.main-nav.active:after {
  background-color: #ececec;
  height: auto;
  top: 0;
  bottom: 0;
}

.ui-tablet #nav a.main-nav:after {
  left: 1%;
  right: 1%;
}

.ui-mobile #nav a.main-nav:after {
  display: none;
}

.ui-mobile #nav a.main-nav {
  font-size: 18px;
  padding: 12px;
  height: auto;
  display: block;
}

.ui-mobile #nav a.main-nav ~ a.main-nav {
  border-top: 2px solid #FAFAFA;
}

#nav a.main-nav.active {
  color: #6f6f6f;
}

.ui-tablet #nav a.main-nav.active {
  background-color: inherit;
}

.ui-mobile #nav a.active {
  background: none;
}

#nav a.main-nav.reserve-room {
  padding: 0;
  width: 235px;
  height: 33px;
  line-height: 36px;
  font-size: 18px;
  letter-spacing: 2px;
  vertical-align: middle;
  color: #FFF;
  background: #651063;
  text-align: center;
  margin-right: 24px;
}

#nav a.main-nav.reserve-room:after {
  display: none;
}

#nav a.main-nav.reserve-room > span,
#nav .opc-btn > span {
  position: relative;
  display: inline-block;
  will-change: transform, opacity;
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
}

#nav .opc-btn > span {
  top: -4px;
}

.ui-tablet #nav .opc-btn > span {
  display: none;
}

#nav a.main-nav.reserve-room > span.hide,
#nav .opc-btn > span.hide {
  opacity: 0.25;
  transform: translateY(-24px);
}

#nav a.main-nav.reserve-room > span.hide.in,
#nav .opc-btn > span.hide.in {
  transform: translateY(24px);
}

#nav a.main-nav.reserve-room > span.new,
#nav .opc-btn > span.new {
  opacity: 0.25;
  transition: none;
  transform: translateY(24px);
}

#nav a.main-nav.reserve-room > span.new.in,
#nav .opc-btn > span.new.in {
  transform: translateY(-24px);
}

.no-touchevents #nav a.main-nav.reserve-room:hover {
  color: #FFF;
}

.ui-mobile #nav a.main-nav.reserve-room {
  float: none;
  margin: 16px auto 12px auto;
}

.ui-tablet #nav a.main-nav.reserve-room {
  width: 200px;
}

.ui-widescreen #nav a.main-nav.reserve-room {
  width: 300px;
}

#nav .nav-over-wrapper {
  display: none;
  position: absolute;
  top: 39px;
  cursor: pointer;
  white-space: initial;
  /*
	opacity		: 0;
	*/
}

.ui-mobile #nav .nav-over-wrapper {
  left: 0 !important;
  width: 100% !important;
}

#nav .nav-over-wrapper .nav-date {
  font-size: 13px;
  margin-bottom: 0;
}

#nav .nav-over-wrapper a {
  text-decoration: none;
}

#nav .nav-over-wrapper .tri {
  position: absolute;
  top: -15px;
  left: 77px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 15px solid #C1C1C1;
  margin: auto;
  z-index: 1000;
}

#nav .nav-over-wrapper .nav-over-inner {
  position: relative;
  padding: 4px;
  background: #E0E0E0;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.35);

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#nav .nav-over-wrapper .nav-over-inner .nav-over-content {
  position: relative;
  background: #FAFAFA;
  padding: 8px;
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.35);
  border-radius: 4px;

  opacity: 1;
}

.ui-widescreen #nav .nav-over-wrapper .nav-over-inner .nav-over-content {
  padding: 16px;
}

#nav .nav-over-wrapper .nav-over-inner .nav-over-content .nav-over-page {
  position: relative;
  width: 130px;
  height: 210px;
  border: 1px solid #E0E0E0;
  padding: 5px;
  display: inline-block;
  *display: inline;
  zoom: 1;
  float: left;
  margin: 0 4px 0 4px;
  font-family: TitleFont, serif;
  color: #050303;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
  background: #FFF;
  transition: border-color 250ms ease-in, background-color 250ms ease-in;
}

.ui-mobile #nav .nav-over-wrapper .nav-over-inner .nav-over-content .nav-over-page {
  width: 100%;
  display: block;
  float: none;
}

.ui-widescreen #nav .nav-over-wrapper .nav-over-inner .nav-over-content .nav-over-page {
  width: 170px;
  height: 250px;
  padding: 10px;
  margin: 0 8px 0 8px;
}

.ui-desktop.no-touchevents #nav .nav-over-wrapper .nav-over-inner .nav-over-content .nav-over-page:hover,
.ui-widescreen.no-touchevents #nav .nav-over-wrapper .nav-over-inner .nav-over-content .nav-over-page:hover {
  border: 1px solid #FAFAFA;
  background: #FAFAFA;
  transition: border-color 250ms ease-out, background-color 250ms ease-out;
}

#nav .nav-over-wrapper .nav-over-inner .nav-over-content .nav-over-page .nav-title {
  font-size: 13px;
  line-height: 13px;
  height: 25px;
  margin: 0 -10px 7px -10px;
  color: #000;
  padding: 0 5px;
}

.no-touchevents #nav .nav-over-wrapper .nav-over-inner .nav-over-content .nav-over-page:hover .nav-title {
  text-decoration: underline;
}

.ui-widescreen #nav .nav-over-wrapper .nav-over-inner .nav-over-content .nav-over-page .nav-title {
  font-size: 16px;
  line-height: 16px;
  height: 32px;
}

#nav .nav-over-wrapper .nav-over-inner .nav-over-content .nav-over-page .nav-desc {
  font-size: 11px;
  font-family: BodyFont, sans-serif;
  color: #666;
  transition: color 0.65s ease-in-out;
}

.ui-widescreen #nav .nav-over-wrapper .nav-over-inner .nav-over-content .nav-over-page .nav-desc {
  font-size: 14px;
}

.no-touchevents #nav .nav-over-wrapper .nav-over-inner .nav-over-content .nav-over-page:hover .nav-desc {
  color: #000;
}

#nav .nav-over-wrapper .nav-over-inner .nav-over-content .nav-over-page .image-viewport {
  width: 100%;
  height: 63px;
  overflow: hidden;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.75);
  border-bottom-right-radius: 3px;
}

.ui-widescreen #nav .nav-over-wrapper .nav-over-inner .nav-over-content .nav-over-page .image-viewport {
  height: 78px;
}

#nav .nav-over-wrapper .nav-over-inner .nav-over-content .nav-over-page .image-viewport img {
  width: 100%;
  height: auto;
  border: none;
}

#right-side-wrapper {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 0;
  width: 25%;
  padding: 0;
  margin: 0;
  text-align: right;
  z-index: 8;
  background: #F5F5F5;
}

.ui-mobile #right-side-wrapper {
  position: relative;
  width: 100%;
  top: 0;
}

.ui-widescreen #right-side-wrapper {
  margin-right: -555px;
  right: 50%;
  width: 300px;
}

.ui-tablet #right-side-wrapper {
  right: 0;
}

#right-side-wrapper h2 {
  text-align: center;
  padding: 20px 10px 10px 10px;
}

#subnav {
  position: sticky;
  top: 33px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  font-size: 14px;
  margin-bottom: 216px;
}

.text-increase #subnav {
  font-size: 16px;
}

.ui-tablet #subnav {
  margin-bottom: 166px;
}

.ui-mobile #subnav {
  position: relative;
  margin: 0;
  top: 0;
}

/*
.ui-tablet #subnav {
	margin-top	: 40px;
}
*/
#subnav .header,
#subnav .sub-header {
  position: relative;
  background-color: #DEDEDE;
  padding: 14px 0;
  text-align: center;
  font-family: TitleFont, serif;
}

#subnav .sub-header {
  display: list-item !important;
  border: none;
  padding: 6px 0;
  margin-top: -1px;
  cursor: pointer;
}

#subnav .collapsed {
  display: none;
}

#subnav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/*
.ui-mobile #subnav > ul > li {
	background		: transparent url(/library/images/global/list-bg.png) bottom right no-repeat;
	background-size	: cover;
}
*/
.ui-mobile #subnav ul li.has-sub {
  font-size: 18px;
  padding-top: 16px;
}

.ui-mobile #subnav ul li.has-sub ul {
  padding: 16px 16px 64px 16px;
}

.ui-mobile #subnav ul li {
  display: none;
}

.ui-mobile #subnav ul li.header {
  display: block;
}

/*
#subnav .has-sub {
	padding-bottom	: 25px;
}
*/
#subnav > ul > li.center-text {
  padding-top: 0;
}

#subnav .center-text {
  text-align: center;
}

#subnav ul.subnav-right {
  padding-top: 50px;
}

.ui-mobile #subnav ul.subnav-right {
  display: none;
}

#subnav ul.subnav-right a {
  padding-right: 10px;
}

#subnav ul ul a {
  margin-left: 15%;
}

#subnav ul ul ul a {
  margin-left: 25%;
}

#subnav ul ul ul ul a {
  margin-left: 35%;
}

#subnav a {
  display: block;
  height: 35px;
  line-height: 35px;
  vertical-align: middle;
  text-decoration: none;
  color: #393c40;
  margin: 0 5%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;
  transition: color 450ms ease-in;
}

#subnav li + li a,
#subnav li > ul > li a {
  border-top: 1px dashed #88898b;
}

#subnav li.header + li a {
  border-top: none;
}

#subnav .header a {
  border-bottom: none;
  font-size: 18px;
  border-bottom: none;
}

.text-increase #subnav .header a {
  font-size: 22px;
}

.ui-mobile #subnav a {
  padding: 10px 0;
  margin: 0;
  text-align: center;
  line-height: 16px;
}

.no-touchevents #subnav a:hover,
#subnav a:active,
#subnav a.active {
  text-decoration: underline;
  transition: color 250ms ease-out;
}

.ui-mobile #subnav li.header a:after {
  content: " Menu";
  position: relative;
}

.ui-mobile #subnav li.header a:before {
  content: "Open ";
  position: relative;
}

.ui-mobile #subnav li.header.active a:after {
  content: "";
}

.ui-mobile #subnav li.header.active a:before {
  content: "Go to ";
}

#content {
  position: static;
  z-index: 5;
}

#body-container {
  position: absolute;
  padding: 0;
  top: 85%;
  width: 100%;
}

.ui-mobile #body-container,
.ui-tablet #body-container {
  padding: 0 0 50px 0;

  border-radius: 0;
}

.ui-mobile #body-container {
  position: relative;
  top: 0;
  padding: 0 0 25px 0;
}

.no-artwork #body-container {
  top: 99px;
}

.ui-mobile .no-artwork #body-container {
  top: 0;
}

#body-gradient-v2 {
  position: relative;
  width: 100%;
  padding: 0 0 20px 0;
  background: #FAFAFA;
  /* background	: url(/library/images/global/V2/bg-content.jpg) repeat-x scroll 0 0 #FFF; */
}

#body-container h1,
.property-list .header {
  position: absolute;
  top: -40px;
  height: 40px;
  width: 912px;
  left: 0;
  font-size: 20px;
  color: #000;
  line-height: 38px;
  vertical-align: middle;
  font-family: TitleFont, serif;
  margin: 0;
  padding: 0 20px;
  background: #F5F5F5;
  z-index: 12;

  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.5);

  border-bottom: none;
}

#body-container h1 {
  top: 0;
  width: 100%;
  border-radius: 0;
}

#body-container h1 span {
  display: block;
  max-width: 1105px;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 16px;
}

.ui-desktop #body-container h1 span {
  max-width: 100%;
}

.full-width #body-container h1 {
  width: 920px;
}

.ui-mobile .property-list .header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  box-shadow: none;
}

.text-increase #body-container h1,
.text-increase .property-list .header {
  font-size: 24px;
}

.ui-mobile #body-container h1,
.ui-tablet #body-container h1 {
  position: relative;
  top: auto;
  width: auto;

  box-shadow: none;
  border-radius: 0;
}

.ui-tablet #body-container h1 {
  width: 100%;
}

.property-list.list-restaurants .btn-wrapper,
.property-list.list-properties .btn-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 6px;
}

.ui-mobile .property-list.list-restaurants .btn-wrapper,
.ui-mobile .property-list.list-properties .btn-wrapper {
  position: relative;
}

.property-list > li.header {
  font-size: 20px;
  top: -37px;
  left: -4px;
  right: -4px;
  width: auto;
  padding: 0;
  text-align: center;
  height: 35px;
  line-height: 35px;
  z-index: 100;
  min-height: 0;
  border: 4px solid #E5C472;
  border-bottom: none;
  box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.75);
}

.no-hashchange .property-list > li.header {
  position: static;
  border: none;
}

.text-increase .property-list > li.header {
  font-size: 24px;
}

.no-touchevents .property-list > li.header:hover {
  background-image: none;
}

.property-list > li {
  position: relative;
  padding: 0;
  margin: 0;
  min-height: 110px;
  z-index: 3;
  overflow: hidden;

  border-radius: 4px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
}


.ui-mobile .property-list > li {
  border-radius: 0;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.25);
}

.property-list > li ~ li {
  margin-top: 24px;
}

.ui-mobile .property-list > li ~ li {
  margin-top: 64px;
}

.ui-mobile .property-list .property-list-body {
  width: 100%;
}

.property-list .property-list-title {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.35);
  background: #FAFAFA;
  font-size: 20px;
  padding: 8px 16px;
}

.ui-mobile .property-list .property-list-title {
  margin: 0;
  text-align: center;
}

.text-increase .property-list .property-list-title {
  font-size: 20px;
}

.property-list .property-list-description {
  position: relative;
  padding: 12px 24px 0 24px;
}

.ui-tablet .property-list .property-list-description,
.ui-mobile .property-list .property-list-description {
  margin: 0;
}

.property-list .property-list-image {
  position: relative;
  top: 0;
  left: 0;
  width: 35%;
  padding-bottom: 32%;
  vertical-align: top;
  float: left;
  margin-right: 16px;
  background-size: auto 100%;
  background-size: cover;
  z-index: 10;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.35);
  background-color: #FFF;

  border-bottom-right-radius: 4px;
}

.ui-mobile .property-list .property-list-image {
  width: 100%;
  padding-bottom: 40%;
}

.property-list .mini .property-list-image {
  height: 135px;
  width: 150px;
  padding-bottom: 0;
  background-position: 50% 50%;
}

.ui-mobile .property-list .mini .property-list-image {
  position: relative;
  padding: 0;
  width: 100%;
  height: 0;
  padding-bottom: 25%;
  overflow: visible;
  float: none;
}

.no-hashchange #body-container h1 {
  position: static;
}

#body-container h1 .extra-title {
  font-family: BodyFont, sans-serif;
  font-size: 16px;
  position: relative;
  margin: 0 0 0 45px;
  padding: 3px 8px;
  white-space: nowrap;
  text-align: center;
  line-height: 36px;
}

.text-increase #body-container h1 .extra-title {
  font-size: 20px;
}

.ui-mobile #body-container h1 .extra-title {
  position: absolute;
  right: 0;
}

#body {
  position: relative;
  color: #000;
  width: 75%;
  padding: 16px 16px 32px 16px;
  z-index: 10;
  min-height: 750px;
  font-size: 12px;
  margin: 0 0 200px 0;
  left: 0;
  top: 40px;
  background: #FFF;
  overflow: hidden;
}

.no-hashchange #body {
  margin-right: 254px;
}

.text-increase #body {
  font-size: 16px;
}

.ui-mobile #body {
  padding: 15px;
  width: 100%;
  margin-bottom: 0;
}

.ui-mobile #body,
.ui-tablet #body {
  overflow: visible;
  overflow-x: hidden;
  overflow-y: visible;
  border-radius: 0;
  box-shadow: none;
  top: 0;
}

.ui-widescreen #body {
  padding: 32px;
  margin: 0 0 200px -554px;
  width: 809px;
  left: 50%;
  box-shadow: 0 -8px 12px rgba(0, 0, 0, 0.25);
}

.ui-widescreen #body.full-width {
  width: 1109px;
}

#body.full-width {
  width: 100%;
}

#body p {
  margin: 5px 0 15px 0;
  text-align: justify;
}

#body p.submit-wrapper {
  text-align: center;
}

#body h2 {
  font-family: TitleFont, serif;
  font-size: 16px;
  margin: 5px 0 2px 0;
}

#body .text-size-btn + h2 {
  margin-top: 0;
}

#body h3 {
  font-family: TitleFont, serif;
  font-size: 14px;
  margin: 5px 0 2px 0;
}

.text-increase #body h2 {
  font-size: 20px;
}

.text-increase #body h3 {
  font-size: 18px;
}

#body .disclaimer {
  font-size: 10px;
}

.text-increase #body .disclaimer {
  font-size: 14px;
}

#right-side-wrapper .under-nav {
  position: relative;
  padding: 10px 20px 0 0;
}

#artwork {
  position: absolute;
  width: 100%;
  bottom: 15%;
  top: 100px;
  z-index: 12;
}

.ui-mobile body #artwork {
  position: relative;
  top: 0;
  bottom: auto;
  min-height: 400px;
  height: 80vh;
}

#artwork.homepage {
  bottom: -165px;
}

#artwork .slide-caps {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

#artwork.homepage .slide-caps {
  bottom: 165px;
}

.ui-mobile #artwork .slide-caps {
  top: 75%;
  height: 25%;
}

.ui-mobile #artwork.homepage .slide-caps {
  top: 0;
  height: auto;
  bottom: 0;
}

#artwork .content {
  display: block;
  position: absolute;
  width: 100%;
  /*
	height				: 100%;
	*/
  top: 0;
  bottom: 0;
  opacity: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  perspective: 400px;
  transform-style: preserve-3d;
  transform: translateZ(0);
  transition: opacity 750ms ease-in-out, visibility 1ms linear 750ms;
}

#artwork.homepage .content {
  bottom: 165px;
  overflow: hidden;
}

/* .ui-mobile #artwork .content {
	height	: 100%;
} */

.ui-mobile #artwork .content.video {
  position: relative;
}

#artwork .video-btn {
  position: absolute;
  bottom: 32px;
  left: 32px;
  width: 64px;
  height: 64px;
  z-index: 56;
  outline: none;
  padding: 12px;
  color: #FFF;
  color: rgba(255, 255, 255, 0.65);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: opacity 350ms ease-out;
}

.ui-mobile #artwork .video-btn {
  bottom: 0;
  left: 0;
}

#artwork .video-btn.hide {
  opacity: 0;
  z-index: 43;
  animation: video-btn-hide 350ms forwards 1 ease-out;
}

#artwork .video-btn svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}

.no-touchevents #artwork .video-btn:hover svg {
  color: #d42128;
}

@keyframes video-btn-hide {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

#artwork .volume-btn {
  position: relative;
  font-size: 0.8em;
  color: #d1d2d4;
  text-decoration: none;
  padding: 16px;
}

#artwork .volume-btn.dock {
  position: absolute;
  z-index: 53;
}

#artwork .volume-btn.dock.bottom-left {
  bottom: 42px;
  right: 32px;
}

.ui-mobile #artwork .volume-btn.dock.bottom-left {
  bottom: 0;
  right: 0;
}

.no-touchevents #artwork .volume-btn:before {
  content: '';
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: 14px;
  height: 1px;
  background: #FFF;
  transform: scaleX(0);
  transition: transform 250ms ease-in;
}

.no-touchevents #artwork .volume-btn:hover:before {
  transform: none;
  transition: transform 250ms ease-out;
}

#artwork .volume-btn:after {
  position: relative;
  content: ' OFF';
  color: #d42128;
  font-weight: 600;
}

#artwork .volume-btn.audio-on:after {
  content: ' ON';
}

#artwork .content .video-js,
#artwork .content video {
  top: 0;
  left: 0;
  background-color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#artwork .content video {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 0;
  transition: opacity 250ms ease-in-out 250ms;
}

#artwork .content video.active {
  opacity: 1;
}

#artwork .vjs-loading-spinner {
  display: none;
}

#artwork .vjs-error-display.vjs-modal-dialog {
  display: none;
}

#artwork .content * {
  filter: inherit;
}

.ui-mobile #artwork .content.has-cap {
  height: 75%;
}

#artwork .content.hide {
  display: none;
}

#artwork .content.active {
  opacity: 1;
}

#artwork .content .art {
  display: none;
  position: relative;
  width: 100%;
  height: auto;
}

#artwork .slide-caps .caption {
  position: absolute;
  padding: 15px;
  font-size: 16px;
  max-width: 20%;
  opacity: 0;
  color: #000;
  background: #FFF;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 0.3em;
  transition: opacity 425ms ease-in-out, visibility 1ms linear 425ms, background-color 250ms ease-in-out, transform 350ms ease-in-out;
}

#artwork .slide-caps .caption.hide {
  visibility: hidden;
}

.ui-widescreen #artwork .slide-caps .caption {
  font-size: 18px;
}

#artwork .slide-caps .caption.active {
  opacity: 1;
  transition: opacity 425ms ease-in-out 500ms, visibility 1ms linear 500ms, transform 350ms ease-in-out;
  transform: translateZ(0);
}

#artwork .slide-caps .caption.active.has-link .cap-viewport {
  cursor: pointer;
}

#artwork .slide-caps .caption .cap-viewport {
  position: relative;
}

#artwork .slide-caps .caption.cap-elegant {
  top: 0;
  bottom: 0;
  left: 5%;
  color: #FFF;
  min-width: 240px;
  background: transparent url(/library/images/global/V2/pixel-000000-65.png) 0 0 repeat;
  box-shadow: 4px 0 12px #000;
}

#artwork .slide-caps .caption.cap-elegant.has-link.hover {
  background: transparent url(/library/images/global/V2/pixel-000000-85.png) 0 0 repeat;
}

#artwork .slide-caps .caption.cap-elegant .overlay {
  display: none;
}

#artwork .slide-caps .caption.cap-elegant .cap-viewport {
  transition: margin-top 250ms ease-in-out;
}

#artwork .slide-caps .caption.cap-center {
  top: 50%;
  left: 50%;
  width: 300px;
  height: 100px;
  margin: -50px 0 0 -150px;
}

#artwork .slide-caps .caption.cap-top {
  top: 8%;
}

#artwork .slide-caps .caption.cap-right {
  right: 3%;
}

#artwork .slide-caps .caption.cap-bottom {
  bottom: 8%;
}

#artwork .slide-caps .caption.cap-left {
  left: 3%;
}

#artwork .slide-caps .caption.cap-image {
  padding: 0;
  background: transparent;
}

#artwork .slide-caps .caption.cap-image * {
  margin: 0;
}

#artwork .slide-caps .caption.cap-image,
#artwork .slide-caps .caption.cap-image .overlay,
#artwork .slide-caps .caption.cap-image .cap-viewport {
  margin: 0 0 -5px 0;
}

#artwork .slide-caps .caption.cap-image img {
  float: none;
  padding-right: 0;
  max-width: 100%;
}

#artwork .slide-caps .caption * {
  margin: 0 0 5px 0;
}

#artwork .slide-caps .caption .disclaimer {
  font-size: 10px;
}

#artwork .slide-caps .caption h3 {
  font-family: TitleFont, serif;
  margin: 0 0 10px 0;
}

#artwork .slide-caps .caption img {
  float: left;
  padding-right: 20px;
}

#artwork .slide-caps .caption .overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
}

#artwork .slide-caps .caption.has-link .overlay {
  cursor: pointer;
}

#artwork .slide-caps .caption.cap-transparent.has-link .overlay {
  cursor: default;
}

#artwork .slide-caps .caption a {
  text-decoration: none;
  color: #3a6493;
  font-weight: bold;
  padding-left: 0;
}

#artwork .slide-caps .caption.cap-transparent a {
  padding-left: 16px;
}

.ui-mobile #artwork .caption.cap-top,
.ui-mobile #artwork .caption.cap-right,
.ui-mobile #artwork .caption.cap-bottom,
.ui-mobile #artwork .caption.cap-left,
.ui-mobile #artwork .caption.cap-center,
.ui-mobile #artwork .caption.cap-elegant,
.ui-mobile #artwork .caption.cap-image {
  background: #FFF;
  color: #000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 10px;
  max-width: none;
  width: auto;
}

#artwork .slide-caps * {
  filter: inherit;
}

#artwork .slide-caps .caption.cap-transparent.cap-light,
#artwork .slide-caps .caption.cap-transparent {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: initial;
  background-color: transparent;
}

#artwork .slide-caps .caption.cap-transparent.cap-light .cap-viewport,
#artwork .slide-caps .caption.cap-transparent .cap-viewport {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 800px;
  height: 250px;
  max-width: 100%;
  max-height: 100%;
  margin: -125px 0 0 -400px;
  text-align: center;
  z-index: 50;
}

#artwork .slide-caps .caption.cap-transparent.cap-light .cap-viewport {
  color: #FFF;
}

.ui-mobile #artwork .slide-caps .caption.cap-transparent.cap-light .cap-viewport,
.ui-mobile #artwork .slide-caps .caption.cap-transparent .cap-viewport {
  top: 25%;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 16px;
}

#artwork .slide-caps .caption.cap-transparent.cap-light .overlay,
#artwork .slide-caps .caption.cap-transparent .overlay {
  background-color: transparent;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0;
  z-index: 30;
}

#artwork .slide-caps .caption.cap-transparent.cap-light .overlay {
  background-color: transparent;
  background-color: rgba(0, 0, 0, 0.35);
}

#artwork .slide-caps .caption.cap-transparent .overlay {
  background-color: transparent;
}

#artwork .slide-caps .caption.cap-transparent.cap-light .btn.call-to-action,
#artwork .slide-caps .caption.cap-transparent .btn.call-to-action {
  text-decoration: none;
  padding: 16px 32px;
  color: #FFF;
}

#artwork .slide-caps .caption.cap-transparent.cap-light .btn.call-to-action:before,
#artwork .slide-caps .caption.cap-transparent .btn.call-to-action:before {
  display: none;
}

#artwork .thumb-dots {
  position: absolute;
  right: 0;
  bottom: 15px;
  left: 0;
  height: 30px;
  text-align: center;
  z-index: 5;

  transition: bottom 425ms ease-in-out 500ms;
}

#artwork.homepage .thumb-dots {
  bottom: 265px;
}

.ui-mobile #artwork.homepage .thumb-dots {
  bottom: 15px;
}

.ui-mobile #artwork .thumb-dots.has-cap {
  bottom: 26%;
}

#artwork .thumb-dots .dot {
  position: relative;
  display: inline-block;
  *display: inline;
  zoom: 1;
  width: 24px;
  height: 24px;
  margin: 5px;
  background: transparent url(/library/images/global/V2/slideshow-dot-sprite.png) -24px 0 no-repeat;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#artwork .thumb-dots .dot .dot-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  background: transparent url(/library/images/global/V2/slideshow-dot-sprite.png) -48px 0 no-repeat;
  opacity: 0;
  transition: opacity 5000ms linear;
}


.csstransitions #artwork .thumb-dots .dot .dot-inner {
  display: none;
}

#artwork .thumb-dots .dot.active .dot-inner {
  opacity: 1;
}

#artwork .thumb-dots .dot-overlay {
  display: none;
  position: absolute;
  left: 0;
  cursor: pointer;
  width: 169px;
  height: 113px;
  bottom: 60%;
  background: transparent url(/library/images/global/V2/slideshow-thumb-background.png) 0 0 no-repeat;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#artwork .thumb-dots .dot-overlay img {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 145px;
  height: 78px;
  filter: inherit;
}

#artwork .thumb-dots .dot .dot-circle {
  display: none;
  position: absolute;
  width: 24px;
  height: 24px;
  clip: rect(0px, 24px, 24px, 12px);

  transition: clip 1ms linear 2500ms;
}

#artwork .thumb-dots .dot.active .dot-circle {
  clip: rect(0, 24px, 24px, 0);
}

#artwork .thumb-dots .dot.active.reset .dot-circle {
  clip: rect(0px, 24px, 24px, 12px);
}

.csstransitions #artwork .thumb-dots .dot .dot-circle {
  display: block;
}

#artwork .thumb-dots .dot .dot-circle .circle-right,
#artwork .thumb-dots .dot .dot-circle .circle-left {
  position: absolute;
  width: 0;
  height: 0;
  border: 12px solid #651063;
  clip: rect(0px, 12px, 24px, 0px);

  border-radius: 50px;

  transition: transform 2500ms linear;
}

#artwork .thumb-dots .dot .dot-circle .circle-left {
  transition: transform 5000ms linear;
}

#artwork .thumb-dots .dot.active .dot-circle .circle-right {
  transform: rotate(180deg);
}

#artwork .thumb-dots .dot.active .dot-circle .circle-left {
  transform: rotate(360deg);
}

#artwork .thumb-dots .dot.active.reset .dot-circle .circle-left,
#artwork .thumb-dots .dot.active.reset .dot-circle .circle-right {
  transform: rotate(0);
}

#footer {
  position: fixed;
  bottom: -165px;
  left: 0;
  right: 0;
  height: 200px;
  background: #E0E0E0;
  z-index: 15;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.35);

  transition: box-shadow 0.65s ease-in-out;
}

.ui-mobile #footer {
  position: relative;
  bottom: auto;
  height: auto;
  overflow: visible;
  box-shadow: none;
}

#footer .footer-viewport {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 1024px;
  margin: 0 0 0 -512px;
}

.ui-mobile #footer .footer-viewport {
  position: relative;
  bottom: auto;
  width: 100%;
  left: 0;
  margin: 0;
}

#footer .facebook-logo,
#footer .instagram-logo {
  position: absolute;
  right: 160px;
  top: 5px;
  z-index: 11;
  width: 25px;
  height: 25px;

  transition: transform 350ms ease-in;
}

#footer .instagram-logo {
  right: 200px;
}

.csstransforms #footer.active .facebook-logo,
.csstransforms #footer.active .instagram-logo {
  transform: translateY(164px);

  transition: transform 350ms ease-out;
}

.no-csstransforms #footer.active .facebook-logo,
.no-csstransforms #footer.active .instagram-logo {
  top: 164px;
}

#footer .facebook-logo img,
#footer .instagram-logo img {
  width: 100%;
  height: 100%;
}

.ui-mobile #footer .facebook-logo,
.ui-mobile #footer .instagram-logo {
  right: auto;
  left: 10px;
  top: 20px;
}

.ui-mobile #footer .instagram-logo {
  left: 62px;
}

.ui-mobile #footer .facebook-logo img,
.ui-mobile #footer .instagram-logo img {
  width: 42px;
  height: 42px;
}

.ui-tablet #footer .facebook-logo,
.ui-tablet #footer .instagram-logo {
  right: 230px;
}

#footer .copyright {
  position: absolute;
  top: 4px;
  left: 20px;
  width: 350px;
  text-align: center;
  font-size: 11px;
  z-index: 9;

  transition: transform 350ms ease-in;
}

#footer .accessibility {
  position: absolute;
  top: 10px;
  left: 47%;
  font-size: 16px;
  z-index: 9;
}

.cssanimations #footer.initiated .copyright,
.cssanimations #footer.initiated .facebook-logo,
.cssanimations #footer.initiated .instagram-logo,
.cssanimations #footer.initiated .links,
.cssanimations #footer.initiated .accessibility {
  animation: footer-above-the-fold 1000ms ease-in-out 250ms 1 normal none running;
}

.cssanimations #footer.active .copyright,
.cssanimations #footer.active .facebook-logo,
.cssanimations #footer.active .instagram-logo,
.cssanimations #footer.active .links,
.cssanimations #footer.active .accessibility {
  animation: none;
}

.csstransforms #footer.active .copyright {
  transform: translateY(155px);

  transition: transform 350ms ease-out;
}

.csstransforms #footer.active .accessibility {
  transform: translateY(160px);
  transition: transform 350ms ease-out;
}

.no-csstransforms #footer.active .copyright {
  top: 155px;
}

.no-csstransforms #footer.active .accessibility {
  top: 165px;
}

@keyframes footer-above-the-fold {
  0% {
    opacity: 0;
    transform: none;
  }
  100% {
    opacity: 1;
  }
}

.ui-mobile #footer .copyright {
  position: relative;
  left: auto;
  right: auto;
  top: 0;
  width: 100%;
  padding: 32px 0;
}

.ui-tablet #footer .copyright {
  left: 130px;
  text-align: left;
}

#footer .links {
  position: absolute;
  top: 8px;
  left: 365px;
  right: 10px;
  z-index: 10;
  width: 450px;

  transition: transform 350ms ease-in;
}

.csstransforms #footer.active .links {
  transform: translateY(164px);
  transition: transform 350ms ease-out;
}

.no-csstransforms #footer.active .links {
  top: 164px;
}

.ui-mobile #footer .links {
  position: relative;
  left: auto;
  top: 15px;
  right: auto;
  width: auto;
  text-align: center;
  margin-left: 105px;
}

.ui-tablet #footer .links {
  top: 7px;
  left: 380px;
}

#footer .links input {
  height: 20px;
  border: 1px solid #fcfbfa;
  font-size: 11px;
  line-height: 20px;
  vertical-align: middle;
  padding: 0 10px;
  background: #000;
  color: #fcfbfa;
}

#footer .links input[type="submit"] {
  color: #fcfbfa;
  height: 22px;

  transition: border-color 0.65s ease-in-out, color 0.65s ease-in-out;
}

#footer .links input:focus {
  color: #E5C472;
}

#footer .links a {
  position: relative;
  display: inline-block;
  *display: inline;
  zoom: 1;
  height: 20px;
  border: 1px solid #000;
  background: #FFF;
  font-size: 11px;
  line-height: 20px;
  vertical-align: middle;
  padding: 0 30px 0 10px;
  color: #000;
  text-decoration: none;
  border-radius: 0.3em;
  transition: color 250ms ease-in, border-color 250ms ease-in;
}

.ui-mobile #footer .links a {
  margin: 5px;
  padding: 10px;
  height: auto;
}

#footer .links a:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  height: 10px;
  width: 10px;
  margin-top: -5px;
  border-radius: 50%;
  background-color: #000;
  transition: background-color 250ms ease-in;
}

.ui-mobile #footer .links a:after {
  display: none;
}

.no-touchevents #footer .links a:hover:after {
  background-color: #651063;
  transition: background-color 250ms ease-out;
}

.no-touchevents #footer .links a:hover,
#footer .links a.hover,
.no-touchevents #footer .links input[type="submit"]:hover,
#footer .links input[type="submit"].hover {
  color: #651063;
  border: 1px solid #651063;
  cursor: pointer;
  transition: color 250ms ease-out, border-color 250ms ease-out;
}

#footer .links form {
  position: relative;
  display: inline-block;
  *display: inline;
  zoom: 1;
  margin-left: 30px;
}

#footer .nav {
  position: absolute;
  top: 6px;
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 250ms ease-in, visibility 1ms linear 250ms;
}

#footer.active .nav {
  opacity: 1;
  visibility: visible;
  transition: opacity 250ms ease-in, visibility 1ms linear;
}

.ui-mobile #footer .nav {
  position: relative;
  top: auto;
  left: auto;
  width: 100%;
  padding: 35px 0;
  opacity: 1;
  visibility: visible;
}

#footer .nav ul {
  position: relative;
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0 10px;
  text-align: center;
  height: 90px;
  overflow: hidden;
}

.ui-mobile #footer .nav ul {
  display: block;
  height: auto;
  border: none;
}

.ui-mobile #footer .nav ul li a {
  display: block;
  padding: 15px;
  height: 0;
  padding: 0;
  overflow: hidden;
  border-bottom: 0px solid #000;
  transition: height 350ms ease-in-out, padding 350ms ease-in-out, border 350ms ease-in-out;
}

.ui-mobile #footer .nav ul .header a {
  height: auto;
  padding: 15px;
  border-bottom: 1px solid #000;
}

.ui-mobile #footer .nav ul .header.active ~ li a {
  height: 50px;
  padding: 15px;
  border-bottom: 1px solid #000;
}

#footer .nav ul .header a {
  color: #000;
  font-size: 15px;
  padding: 5px;
  text-decoration: underline;
}

#footer .nav ul li a {
  position: relative;
  font-size: 12px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
  transition: color 250ms ease-in;
}

.no-touchevents #footer .nav a:hover {
  color: #651063;
  text-decoration: underline;
  transition: color 250ms ease-out;
}

#footer .logos {
  position: absolute;
  top: 92px;
  left: 0;
  right: 0;
  text-align: center;
  padding-top: 12px;
  opacity: 0;
  transition: opacity 250ms ease-in;
}

#footer.active .logos {
  opacity: 1;
  transition: opacity 250ms ease-out 150ms;
}

.ui-mobile #footer .logos {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  border: none;
  opacity: 1;
}

#footer .logos a {
  position: relative;
  display: inline-block;
  width: 140px;
  height: 50px;
  background: transparent url('/library/images/footer-logos/sprite.png') 0 0 no-repeat;
  opacity: 0.5;
  transition: opacity 250ms ease-in;
}

.touchevents #footer .logos a,
.no-touchevents #footer .logos a:hover {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

#footer .logos a.peppermill-lv {
  background-position: -750px 0px;
}

#footer .logos a.peppermill-reno {
  background-position: -900px 0px;
}

#footer .logos a.rainbow-wendover {
  background-position: -600px 0px;
}

#footer .logos a.peppermill-wendover {
  background-position: -300px 0px;
}

#footer .logos a.montego-bay {
  background-position: -450px 0px;
}

#footer .logos a.rainbow-henderson {
  background-position: -150px 0px;
}


/*fonts moved to head.php*/


/*Homepage*/
#home-slider {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}


/*Misc*/
.clearme {
  clear: both;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
}

.generic-btn {
  position: relative;
  border: 1px solid #000;
  color: #010100;
  padding: 5px 10px;
  margin: 10px;
  text-decoration: none;
  font-size: 12px;
  display: inline-block;
  *display: inline;
  zoom: 1;
  white-space: nowrap;
  line-height: 25px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 0.3em;
  overflow: hidden;
}

.generic-btn {
  z-index: 1;
}

.generic-btn:before,
.generic-btn:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -5;
}

.generic-btn:before {
  background: #FFF;
  z-index: -10;
}

.generic-btn:after {
  opacity: 0;
  background: #F5F5F5;
  will-change: opacity, transform;
  transform-origin: 50% 50%;
  transform: scale(1, 0);
  transition: transform 250ms ease-in, opacity 250ms ease-in;
}

.no-touchevents .generic-btn:hover:after {
  opacity: 1;
  transform: none;
  transition: transform 250ms ease-out, opacity 250ms ease-out;
}

.generic-btn.no-btn:before,
.generic-btn.no-btn:after {
  display: none;
}

#body .generic-btn {
  color: #000;
}

.text-increase .generic-btn {
  font-size: 16px;
}

.generic-btn.inactive,
.generic-btn.inactive:hover {
  border: 1px solid #C1C1C1;
  cursor: default;
  background: #C1C1C1;
}

.generic-btn.no-btn,
.generic-btn.no-btn:hover {
  border: none;
  cursor: default;
  background: none;
}

/* .no-touchevents .generic-btn:hover,
.no-touchevents #body .pepvalidate input[type="submit"]:hover {

} */


/* lists */
.reg-list {
  list-style: none;
  padding: 0;
  margin: 50px 0 0 0;
}

.ui-mobile .reg-list {
  margin: 50px -15px 0 -15px;
}

.ui-tablet .reg-list .mini,
.ui-mobile .reg-list .mini {
  border-top: 4px solid #E5C472;
}

.reg-list .reg-list-hide {
  display: none;
}

.reg-list .room-amenities {
  clear: both;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 350ms ease-in-out, padding 350ms ease-in-out, opacity 350ms ease-in-out;
}

.reg-list .show-details .room-amenities {
  max-height: 500px;
  opacity: 1;
  padding: 16px 0;
}


/* calendar */
td.calendar-day {
  font-size: 12px;
  position: relative;
  background: #E0E0e0;
  color: #000;
  color: rgba(0, 0, 0, 0.87);
  z-index: 3;
  width: 120px;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 250ms ease-in, transform 250ms ease-in;
}

.text-increase td.calendar-day {
  font-size: 16px;
}

.ui-mobile td.calendar-day,
.ui-tablet td.calendar-day {
  width: 14%;
}

.no-touchevents td.calendar-day:hover {
  z-index: 5;
  background: #F5F5F5;
}

td.calendar-day.rows-1 {
  height: 40px;
}

td.calendar-day.rows-2 {
  height: 80px;
}

td.calendar-day.rows-3 {
  height: 120px;
}

td.calendar-day.no-data.no-data-week,
td.calendar-day.no-data.no-data-week .cell-wrapper {
  height: 35px;
}

td.calendar-day.disabled {
  cursor: default;
  opacity: 0.25;
}

.no-touchevents #reserve-wrapper td.calendar-day.disabled.hover:hover,
#reserve-wrapper td.calendar-day.disabled.hover {
  background: initial;
}

.cell-wrapper:after {
  border: none !important;
}

* html div.calendar-day {
  height: 80px;
}

div.calendar-day-np {
  height: 120px;
}

td.calendar-day-head {
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 0;
}

.text-increase td.calendar-day-head {
  font-size: 20px;
}

.day-number {
  position: absolute;
  top: 0;
  right: 0;
  color: #000;
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
  font-size: 9px;
  text-align: center;
  padding: 4px;
  margin: 0;
  z-index: 10;
  transition: color 250ms ease-in;
}

.text-increase .day-number {
  font-size: 13px;
}

.no-touchevents td.calendar-day.hover:hover .day-number,
td.calendar-day.hover .day-number {
  color: rgba(255, 255, 255, 1);
  transition: color 250ms ease-out;
}

td.calendar-day.hover.delay-1 .day-number {
  transition-delay: 50ms;
}

td.calendar-day.hover.delay-2 .day-number {
  transition-delay: 100ms;
}

td.calendar-day.hover.delay-3 .day-number {
  transition-delay: 150ms;
}

td.calendar-day.hover.delay-4 .day-number {
  transition-delay: 200ms;
}

td.calendar-day.hover.delay-5 .day-number {
  transition-delay: 250ms;
}

td.calendar-day.hover.delay-6 .day-number {
  transition-delay: 300ms;
}

td.calendar-day.hover.delay-7 .day-number {
  transition-delay: 350ms;
}

td.calendar-day.hover.delay-8 .day-number {
  transition-delay: 400ms;
}

td.calendar-day.hover.delay-9 .day-number {
  transition-delay: 450ms;
}

td.calendar-day.hover.delay-10 .day-number {
  transition-delay: 500ms;
}

td.calendar-day.hover.delay-11 .day-number {
  transition-delay: 550ms;
}

td.calendar-day.hover.delay-12 .day-number {
  transition-delay: 600ms;
}

td.calendar-day.hover.delay-13 .day-number {
  transition-delay: 650ms;
}

/* shared */
.calendar-legend {
  position: relative;
}

.calendar-legend .label {
  position: relative;
  top: 10px;
}

.no-touchevents .calendar-legend .calendar-pref-icon:hover,
.calendar-legend .calendar-pref-icon.hover {
  cursor: pointer;
  padding: 5px 50px;
}

.calendar-legend .calendar-pref-icon.inactive {
  opacity: 0.25;
}

#body .calendar .calendar-rate {
  height: 40px;
  line-height: 40px;
  font-weight: normal;
  vertical-align: middle;
  text-align: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.calendar .calendar-rate.inactive {
  opacity: 0.15;
}

.calendar-nav {
  position: relative;
  display: inline-block;
  *display: inline;
  zoom: 1;
  width: 16px;
  height: 16px;
  padding: 0;
  margin: 0 10px;
  background-color: #F0DEAE;
  background-repeat: no-repeat;
  border: 1px solid #000;
  z-index: 100;
  border-radius: 50%;
  transform: scale(1.25);
  transition: all 0.65s ease-in-out;
}

.no-touchevents .calendar-nav:hover {
  border: 1px solid #FFF;
  transform: scale(2);
}

.calendar-nav.next {
  background-image: url(/admin/library/css/ui-darkness/images/ui-icons_222222_256x240.png);
  background-position: -32px -160px;
}

.calendar-nav.prev {
  background-image: url(/admin/library/css/ui-darkness/images/ui-icons_222222_256x240.png);
  background-position: -48px -160px;
}

.calendar-wrapper {
  position: relative;
  width: 687px;
  overflow: hidden;
  margin-left: -47px;
  zoom: 1;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.ui-mobile #body .calendar-wrapper,
.ui-tablet #body .calendar-wrapper {
  width: 110%;
  left: -5%;
  margin-left: 0;
}

.calendar-wrapper .calendar-viewport {
  position: relative;
  margin: 10px 23px 10px 23px;
}

.calendar-wrapper .calendar-viewport h2 {
  background: #F5F5F5;
  padding: 10px;
  border-top: 2px solid #000;
  text-align: center;
}

.calendar-wrapper .calendar-viewport .reserve-form {
  background: #F9DC92;
  padding: 10px 10px 25px 10px;
}

.calendar .cell-wrapper {
  position: relative;
  display: block;
  height: 40px;
  overflow: hidden;
  text-decoration: none;
}

td.calendar-day.rows-2 .cell-wrapper {
  height: 80px;
}

td.calendar-day.rows-3 .cell-wrapper {
  height: 120px;
}


.grid-wrapper {
  position: relative;
  width: 195px;
  float: left;
  margin: 0 15px 15px 0;
}

.vertical-grid-list .grid-wrapper {
  position: relative;
  width: 240px;
  margin: 0 0 15px 10px;
  text-align: left;
  border: 4px solid #e5c472;
  border-right: none;
  background: #FBF9EE;

  border-top-left-radius: 3px;

  border-bottom-left-radius: 3px;

  box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.75);
}

.grid-wrapper .grid-title {
  position: relative;
  height: 35px;
  line-height: 35px;
  vertical-align: middle;
  background: #F9DC92;
  font-family: TitleFont, serif;
  font-size: 16px;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
}

.text-increase .grid-wrapper .grid-title {
  font-size: 20px;
}

.grid-wrapper .grid-title .swap-views {
  font-size: 12px;
}

.text-increase .grid-wrapper .grid-title .swap-views {
  font-size: 16px;
}

.grid-wrapper .grid-description {
  position: relative;
  min-height: 100px;
  overflow: hidden;
}

.vertical-grid-list .grid-wrapper .grid-description {
  height: 200px;
}

.grid-wrapper .grid-description > span {
  z-index: 7;
}

.grid-wrapper .grid-description .grid-image {
  position: relative;
  float: left;
  height: 200px;
  width: 200px;
  margin: 0 10px 10px 0;
  z-index: 5;
}

.vertical-grid-list .grid-wrapper .grid-description .grid-image {
  width: 240px;
  height: auto;
}

.grid-wrapper .grid-description .grid-image img {
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  height: auto;
}


.ui-dialog .ui-dialog-title {
  font-family: TitleFont, serif;
}

.ui-dialog .ui-dialog-content {
  font-family: BodyFont, sans-serif;
}


/*reserve-form*/
#overlay-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 50px;
  margin: -35px 0 0 -160px;
  z-index: 1000;
  padding: 20px;
  /*
	background	: #000;
	background	: rgba(0, 0, 0, 0);

	border-radius			: 25px;

	box-shadow			: inset 0 0 100px black, 0 0 2px black;
	*/
}

#overlay-loader span {
  position: relative;
  display: inline-block;
  *display: inline;
  zoom: 1;
  width: 50px;
  height: 50px;
  background: transparent url(/library/images/global/loading-dot-inactive.png) 0 0 no-repeat;
  margin: 0 0 0 10px;

  border-radius: 50%;

  box-shadow: 0 0 6px #000;
}

#overlay-loader span img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  margin-top: 0;
  margin-left: 0;
}

#reserve-wrapper {
  position: fixed;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 40;
  opacity: 0;
  visibility: hidden;
  transform: translateY(24px);
  transition: opacity 250ms ease-in-out 1ms, visibility 1ms linear 375ms, transform 250ms ease-in-out;
}

.ui-tablet #reserve-wrapper {
  top: 0 !important;
  bottom: 0 !important;
  height: auto !important;
}

.ui-mobile #reserve-wrapper {
  top: 0 !important;
  bottom: 0 !important;
  height: auto !important;
}

#reserve-wrapper.active {
  opacity: 1;
  visibility: visible;
  z-index: 45;
  transform: none;
  transition: opacity 250ms ease-in-out 125ms, visibility 1ms linear, transform 250ms ease-in-out 125ms;
}

#reserve-wrapper .reserve-viewport {
  position: absolute;
  width: 966px;
  top: 0;
  bottom: 0;
  left: 50%;
  margin: 0 0 0 -483px;
  background-color: #FFF;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 4px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.ui-tablet #reserve-wrapper .reserve-viewport {
  left: 0;
  margin-left: 0;
  width: 100%;
  overflow: auto;
  border-radius: 0;
}

.ui-mobile #reserve-wrapper .reserve-viewport {
  margin: 0;
  height: auto;

  box-shadow: none;

  border-radius: 0;
}

#reserve-wrapper .warning-message {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fcfcf2;
  z-index: 120;
  color: #000;
  padding: 20px;
}

#reserve-wrapper .warning-message h3 {
  font-size: 25px;
}

#reserve-wrapper .warning-message p {
  font-size: 18px;
}

#reserve-wrapper .reserve-properties {
  position: absolute;
  top: 50px;
  height: 50px;
  left: 10px;
  width: 700px;
  padding: 12px 0 0 165px;
}

.ui-mobile #reserve-wrapper .reserve-properties {
  position: relative;
  top: 0;
  margin-top: 16px;
  left: 0;
  padding-left: 60px;
  width: auto;
  height: auto;
}

.ui-tablet #reserve-wrapper .reserve-properties {
  text-align: left;
}

#reserve-wrapper .reserve-properties:before /* ,
#reserve-wrapper .reserve-properties:after  */
{
  position: absolute;
  top: 10px;
  left: 32px;
  /* width			:	135px; */
  height: 28px;
  line-height: 28px;
  vertical-align: middle;
  content: "CHOOSE HOTEL";
  font-size: 18px;
  font-weight: 600;
  z-index: 5;
  transition: opacity 250ms ease-out;
}

.ui-mobile #reserve-wrapper .reserve-properties:before {
  content: "CHOOSE\AHOTEL";
  left: 6px;
  width: 60px;
  height: auto;
  font-size: 16px;
  text-align: center;
}

@keyframes reserve-properties-highlight-title {
  0% {
    opacity: 0;
    transform: scale(1, 1);
  }
  10% {
    opacity: 1;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform: scale(1.3, 1.5);
  }
}

#reserve-wrapper .reserve-properties .prop {
  position: relative;
  margin-left: 64px;
  width: 100px;
  height: 40px;
  padding: 8px 0;
  margin-top: 1px;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  transition: opacity 250ms ease-in, transform 250ms ease-in;
}

.ui-mobile #reserve-wrapper .reserve-properties .prop {
  display: block;
}

#reserve-wrapper .reserve-properties .prop.WB1 {
  width: 109px;
}

#reserve-wrapper .reserve-properties.property-chosen .prop,
#reserve-wrapper .reserve-properties.property-chosen:before {
  opacity: 0.35;
}

#reserve-wrapper .reserve-properties.property-chosen .prop.active {
  opacity: 1;
  transform: scale(1.2);
  transition: opacity 250ms ease-out, transform 250ms ease-out;
}

.ui-tablet #reserve-wrapper .reserve-properties .prop {
  margin-left: 55px;
}

#reserve-wrapper .reserve-properties .prop svg {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: -8px;
  transition: color 250ms ease-in;
}

.ui-mobile #reserve-wrapper .reserve-properties .prop svg {
  margin-top: -9px;
}

#reserve-wrapper .reserve-properties .prop .bar {
  position: absolute;
  bottom: 10px;
  left: -24px;
  right: 0;
  height: 2px;
  background: #651063;
  opacity: 0;
  visibility: hidden;
  transform: scale(0, 1);
  transition: opacity 1000ms ease-in-out, visibility 1ms linear 1000ms, transform 1000ms ease-in-out;
}

#reserve-wrapper .reserve-properties.highlight .prop.highlight .bar {
  opacity: 1;
  visibility: visible;
  transform: none;
  transition: opacity 1000ms ease-in-out 500ms, visibility 1ms linear, transform 1000ms ease-in-out 500ms;
}

#reserve-wrapper .reserve-properties .prop.active svg {
  color: #651063;
  transition: color 250ms ease-out;
}

#reserve-wrapper .reserve-properties .prop img {
  margin-top: -5px;
}

#reserve-wrapper .reserve-properties .prop:before,
#reserve-wrapper .reserve-properties .prop:after {
  position: absolute;
  content: "";
  top: -3px;
  left: -30px;
  width: 25px;
  height: 25px;
  background: transparent url(/library/images/global/V2/checkbox-bg-sprite-larger.png) 0 0;
  transition: opacity 250ms ease-out, visibility 1ms linear;
}

#reserve-wrapper .reserve-properties .prop:after {
  background-position: -25px 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 250ms ease-out, visibility 1ms linear 250ms;
}

#reserve-wrapper .reserve-properties .prop.active:before {
  visibility: hidden;
  opacity: 0;
  transition: opacity 250ms ease-out, visibility 1ms linear 250ms;
}

#reserve-wrapper .reserve-properties .prop.active:after {
  visibility: visible;
  opacity: 1;
  transition: opacity 250ms ease-out, visibility 1ms linear;
}

#reserve-wrapper .error-msg {
  position: absolute;
  width: 450px;
  height: 150px;
  top: 50%;
  left: 50%;
  margin: -75px 0 0 -225px;
  text-align: center;
}

#reserve-wrapper.choose-date .slider-fade {
  position: absolute;
  top: 100px;;
  right: 256px;
  bottom: 10px;
  width: 25px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1); /* IE6-9 */
}

#reserve-wrapper.choose-date .slider-fade.left {
  right: auto;
  left: 0px;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1); /* IE6-9 */
}

.ui-mobile #reserve-wrapper.choose-date .slider-fade {
  display: none;
}

#reserve-wrapper .reserve-viewport.choose-date {
  bottom: auto;
  overflow: hidden;
  height: 450px;

  background: #FFF;
  background: -moz-linear-gradient(top, rgba(153, 153, 153, 1) 0%, rgba(153, 153, 153, 1) 50px, rgba(224, 224, 224, 1) 50px, rgba(224, 224, 224, 1) 100px, rgba(255, 255, 255, 1) 100px, rgba(255, 255, 255, 1) 100%);
  background: -webkit-linear-gradient(top, rgba(153, 153, 153, 1) 0%, rgba(153, 153, 153, 1) 50px, rgba(224, 224, 224, 1) 50px, rgba(224, 224, 224, 1) 100px, rgba(255, 255, 255, 1) 100px, rgba(255, 255, 255, 1) 100%);
  background: linear-gradient(to bottom, rgba(153, 153, 153, 1) 0%, rgba(153, 153, 153, 1) 50px, rgba(224, 224, 224, 1) 50px, rgba(224, 224, 224, 1) 100px, rgba(255, 255, 255, 1) 100px, rgba(255, 255, 255, 1) 100%);
}

.ui-tablet #reserve-wrapper .reserve-viewport.choose-date {
  height: 500px;
}

.ui-mobile #reserve-wrapper .reserve-viewport.choose-date {
  left: 0 !important;
  width: 100%;
  height: 100% !important;
  overflow: auto;
  background: #FFF;
}

#reserve-wrapper .inner-viewport {
  position: relative;
  margin-top: 60px;
  width: 670px;
  height: 350px;
  max-height: 100%;
  overflow: hidden;
}

.ui-tablet #reserve-wrapper .inner-viewport {
  width: 90%;
  margin: 60px auto 0 auto;
}

.ui-mobile #reserve-wrapper .inner-viewport {
  height: auto;
  width: 100%;
  margin: 0;
  top: -42px;
}

#reserve-wrapper .calendar-prev,
#reserve-wrapper .calendar-next,
#reserve-wrapper .prev,
#reserve-wrapper .next {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 10;
  border-radius: 50%;
}

.no-touchevents #reserve-wrapper .calendar-prev,
.no-touchevents #reserve-wrapper .calendar-next,
.no-touchevents #reserve-wrapper .prev,
.no-touchevents #reserve-wrapper .next {
  opacity: 0.5;
  transition: opacity 250ms ease-in;
}

#reserve-wrapper .prev,
#reserve-wrapper .next {
  top: 17px;
}

#reserve-wrapper .calendar-prev svg,
#reserve-wrapper .calendar-next svg,
#reserve-wrapper .prev svg,
#reserve-wrapper .next svg {
  width: 100%;
  height: 100%;
}

.no-touchevents #reserve-wrapper .calendar-prev:hover,
.no-touchevents #reserve-wrapper .calendar-next:hover,
.no-touchevents #reserve-wrapper .prev:hover,
.no-touchevents #reserve-wrapper .next:hover {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.ui-mobile #reserve-wrapper .calendar-prev,
.ui-mobile #reserve-wrapper .calendar-next {
  position: relative;
  top: 0;
  display: block;
  left: auto;
  float: left;
  margin-top: 16px;
  background: #FFF;
  border-radius: 0;
}

.ui-mobile #reserve-wrapper .calendar-next {
  float: right;
}

#reserve-wrapper .calendar-prev {
  top: 110px;
  left: 10px;
}

#reserve-wrapper .calendar-next {
  top: 110px;
  left: 625px;
}

.ui-tablet #reserve-wrapper .calendar-next {
  left: auto;
  right: 10px;
}

.no-touchevents #reserve-wrapper .calendar-prev,
.no-touchevents #reserve-wrapper .calendar-next {
  opacity: 0.75;
}

.no-touchevents #reserve-wrapper .calendar-prev:hover,
.no-touchevents #reserve-wrapper .calendar-next:hover {
  opacity: 1;
}

#reserve-wrapper.choose-date .inner-viewport {
  margin-top: 50px;
  width: 710px;
  height: 370px;
  position: relative;
}

.ui-mobile #reserve-wrapper.choose-date .inner-viewport {
  width: 100%;
  height: auto;
  margin-bottom: 8px;
}

#reserve-wrapper.choose-room .inner-viewport {
  background: transparent url(/library/images/global/choose-room-stripes.png) 0 191px repeat-x;
}

/*
#reserve-wrapper.choose-room {
	overflow	: auto;
	overflow-x	: auto;
	overflow-y	: hidden;
}
*/
#reserve-wrapper.full-height {
  top: 0;
  height: 100%;
  width: 964px;
  margin: 0 0 0 -482px;

  box-shadow: none;

  border-radius: 0;
}

#reserve-wrapper .guests,
#reserve-wrapper .nights,
#reserve-wrapper .check-in,
#reserve-wrapper .check-out {
  position: absolute;
  right: 65px;
  width: 125px;
  height: 25px;
  text-align: center;
}

.ui-mobile #reserve-wrapper .check-in,
.ui-mobile #reserve-wrapper .check-out {
  position: relative;
  float: left;
  width: 50%;
  top: 16px;
  right: auto;
  margin: 0;
  z-index: 100;
  height: auto;
}

.ui-mobile #reserve-wrapper .check-out {
  left: auto;
  right: 0;
}

.ui-mobile #reserve-wrapper .nights {
  display: none;
}

.ui-tablet #reserve-wrapper .check-in,
.ui-tablet #reserve-wrapper .check-out,
.ui-tablet #reserve-wrapper .nights {
  display: none;
}

.ui-tablet #reserve-wrapper .calendar-next
#reserve-wrapper .guests,
#reserve-wrapper .nights {
  top: 275px;
}

#reserve-wrapper .check-in {
  top: 115px;
}

#reserve-wrapper .check-out {
  top: 195px;
}

#reserve-wrapper .guests input,
#reserve-wrapper .nights input {
  width: 32px;
  height: 32px;
  line-height: 20px;
  vertical-align: middle;
  text-align: center;
  font-size: 16px;
  margin: 15px 0 0 0;
  background: #E0E0E0;
  font-family: BodyFont, sans-serif;
  border: 1px solid #333;
  border-radius: 50%;
  transition: all 250ms ease-out;
}

.no-touchevents #reserve-wrapper .guests input,
.no-touchevents #reserve-wrapper .nights input {
  opacity: 0.5;
}

#reserve-wrapper .check-in input,
#reserve-wrapper .check-out input {
  width: 125px;
  /*background		: none;
	border			: none;*/
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  text-align: center;
  font-size: 16px;
  margin: 12px 0 0 -10px;
  color: rgba(0, 0, 0, 0.87);
  border-color: rgba(0, 0, 0, 0.37);

}

.ui-mobile #reserve-wrapper .check-in input,
.ui-mobile #reserve-wrapper .check-out input {
  margin: 10px 0;
  padding: 10px 0;
  width: 100%;
  border-left: none;
  border-right: none;
  border-radius: 0;
}

.no-touchevents #reserve-wrapper .guests:hover input,
.no-touchevents #reserve-wrapper .nights:hover input {
  opacity: 1;
}

#reserve-wrapper .guests .title,
#reserve-wrapper .nights .title,
#reserve-wrapper .check-in .title,
#reserve-wrapper .check-out .title {
  position: absolute;
  top: -10px;
  left: -25px;
  right: -25px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.ui-mobile #reserve-wrapper .check-in .title,
.ui-mobile #reserve-wrapper .check-out .title {
  left: 0;
  right: 0;
  background: #FCFCF2;
  height: 20px;
}

#reserve-wrapper .check-in .title,
#reserve-wrapper .check-out .title {
  cursor: pointer;
}

#reserve-wrapper .reset-btn {
  position: absolute;
  top: 45px;
  right: 10px;
  margin-top: 16px;
}

.ui-tablet #reserve-wrapper .reset-btn,
.ui-tablet #reserve-wrapper .offer-btn {
  top: auto;
  right: auto;
  bottom: 16px;
  left: 100px;
}

.ui-tablet #reserve-wrapper .offer-btn {
  left: 230px;
}

.ui-mobile #reserve-wrapper .reset-btn,
.ui-mobile #reserve-wrapper .offer-btn {
  display: none;
}

#reserve-wrapper .offer-btn {
  position: absolute;
  top: 45px;
  right: 128px;
  margin-top: 16px;
}

#reserve-wrapper .reserve-iframe {
  position: absolute;
  top: 0;
  left: 50%;
  width: 964px;
  height: 100%;
  margin: 0 0 0 -482px;
  border: none;
  padding: 0;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
}

#reserve-wrapper .border-grad {
  position: absolute;
  top: -50px;
  height: 500px;
  width: 300px;
  z-index: 100;
}

#reserve-wrapper .border-grad.left-grad {
  left: -300px;
  background: -moz-linear-gradient(left, rgba(252, 252, 242, 1) 0%, rgba(252, 252, 242, 0.75) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(252, 252, 242, 1)), color-stop(100%, rgba(252, 252, 242, 0.75))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, rgba(252, 252, 242, 1) 0%, rgba(252, 252, 242, 0.75) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, rgba(252, 252, 242, 1) 0%, rgba(252, 252, 242, 0.75) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left, rgba(252, 252, 242, 1) 0%, rgba(252, 252, 242, 0.75) 100%); /* IE10+ */
  background: linear-gradient(to right, rgba(252, 252, 242, 1) 0%, rgba(252, 252, 242, 0.75) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcf2', endColorstr='#bffcfcf2', GradientType=1); /* IE6-9 */
}

#reserve-wrapper .border-grad.right-grad {
  right: -300px;
  background: -moz-linear-gradient(left, rgba(252, 252, 242, 0.75) 0%, rgba(252, 252, 242, 1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(252, 252, 242, 0.75)), color-stop(100%, rgba(252, 252, 242, 1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, rgba(252, 252, 242, 0.75) 0%, rgba(252, 252, 242, 1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, rgba(252, 252, 242, 0.75) 0%, rgba(252, 252, 242, 1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left, rgba(252, 252, 242, 0.75) 0%, rgba(252, 252, 242, 1) 100%); /* IE10+ */
  background: linear-gradient(to right, rgba(252, 252, 242, 0.75) 0%, rgba(252, 252, 242, 1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bffcfcf2', endColorstr='#fcfcf2', GradientType=1); /* IE6-9 */
}

#reserve-wrapper h2.viewport-title {
  position: relative;
  height: 50px;
  text-align: center;
  margin: 0 auto;
  padding: 10px 5px;
  font-family: TitleFont, serif;
  font-size: 22px;
  color: #F5F5F5;
  background: #999;
  text-shadow: 1px 2px 3px #000;
}

#reserve-wrapper .room-slider {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
}

.ui-mobile #reserve-wrapper .room-slider {
  position: relative;
  height: auto;
  width: 910% !important;
}

#reserve-wrapper.choose-date .room-slider {
  top: 0;
  padding-left: 25px;
}

.ui-mobile #reserve-wrapper.choose-date .room-slider {
  padding-left: 0;
  white-space: nowrap;
}

#reserve-wrapper .reserve-room {
  position: relative;
  border: 1px solid #000;
  border: 1px solid rgba(0, 0, 0, 0.2);
  text-align: justify;
  padding: 10px;

  box-shadow: inset -100px -100px 200px rgba(0, 0, 0, 0.1);

  border-radius: 3px;
}

#reserve-wrapper .reserve-room h3 {
  font-size: 14px;
  color: #000;
  margin: 7px 10px 0 0;
  display: inline-block;
  *display: inline;
  zoom: 1;
  height: 20px;
}

#reserve-wrapper .reserve-room p {
  font-size: 12px;
  color: #000;
  padding: 0 15px;
  margin: 5px 0;
}

#reserve-wrapper .reserve-room .text {
  position: relative;
  margin-left: 260px;
}

#reserve-wrapper .reserve-room .property {
  position: relative;
  height: 20px;
  padding: 0;
  display: inline-block;
  *display: inline;
  zoom: 1;
}

#reserve-wrapper .reserve-room .pref {
  text-align: center;
  margin: 0 0 7px 0;
  padding: 0;
}

#reserve-wrapper .reserve-room .room-img {
  position: relative;
  width: 250px;
  height: auto;
  border: none;
  float: left;

  border-radius: 3px;
}

#reserve-wrapper .reserve-calendar-next,
#reserve-wrapper .reserve-calendar-previous,
#reserve-wrapper .reserve-calendar-close {
  position: absolute;
  top: 0;
  height: 48px;
  line-height: 16px;
  width: 128px;
  vertical-align: middle;
  margin: 0;
  z-index: 110;
  color: #000;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  transform: translate3d(0, 0, 0);
  transition: all 0.375s ease-in-out;
  opacity: 1;
}

#reserve-wrapper .reserve-calendar-next span span {
  font-size: 10px;
}

.ui-mobile #reserve-wrapper .reserve-calendar-next,
.ui-mobile #reserve-wrapper .reserve-calendar-previous {
  position: relative;
  top: auto;
  left: auto;
  width: 50%;
  height: auto;
  text-align: right;
  float: right;
}

.ui-mobile #reserve-wrapper .reserve-calendar-next span span {
  display: none;
}

#reserve-wrapper .reserve-calendar-next {
  right: 5px;
  top: 375px;
  width: 175px;
  transition: background-color 0.375s ease-in-out;
}

.ui-tablet #reserve-wrapper .reserve-calendar-next {
  top: 430px;
}

#reserve-wrapper .reserve-calendar-next.highlight {
  animation: reserve-next-step-highlight 1500ms ease-in-out 1ms infinite normal none running;
}

@keyframes reserve-next-step-highlight {
  0% {
    color: #000;
  }
  50% {
    color: #651063;
  }
  100% {
    color: #000;
  }
}

#reserve-wrapper .reserve-calendar-next svg {
  width: 48px;
  height: 48px;
  vertical-align: middle;
}

#reserve-wrapper.showgate .reserve-calendar-next,
#reserve-wrapper.flightgate .reserve-calendar-next {
  background-color: #FCFCF2;
}

#reserve-wrapper .reserve-calendar-previous {
  background: transparent url(/library/images/global/arrow-left-white-48.png) top left no-repeat;
  left: 15px;
  text-align: right;
}

#reserve-wrapper .reserve-calendar-close {
  position: relative;
  display: none;
  width: 50%;
  float: left;
  text-align: left;
  padding: 7px 0 0 16px;
}

.ui-tablet #reserve-wrapper .reserve-calendar-close,
.ui-mobile #reserve-wrapper .reserve-calendar-close {
  display: block;
}

.ui-tablet #reserve-wrapper .reserve-calendar-close {
  width: auto;
  position: absolute;
  left: 8px;
  bottom: 6px;
  top: auto;
}

#reserve-wrapper.showgate .reserve-calendar-previous,
#reserve-wrapper.showgate .reserve-calendar-close,
#reserve-wrapper.flightgate .reserve-calendar-close,
#reserve-wrapper.flightgate .reserve-calendar-previous {
  background-color: #FCFCF2;
}

.no-touchevents #reserve-wrapper .reserve-calendar-next:hover {
  background-color: #F9DC92;
}

.no-touchevents #reserve-wrapper .reserve-calendar-previous:hover,
.no-touchevents #reserve-wrapper .reserve-calendar-close:hover {
  opacity: 1;
}

#reserve-wrapper .calendar-wrapper {
  float: left;
  margin: 0;
  width: 340px;
  top: 0;
}

.ui-mobile #reserve-wrapper .calendar-wrapper {
  width: 11%;
}

.ui-mobile #reserve-wrapper .calendar {
  width: 90%;
  margin: 0 auto;
}

#reserve-wrapper .calendar-wrapper .calendar-viewport {
  margin-top: 0;
}

.ui-mobile #reserve-wrapper .calendar-wrapper .calendar-viewport {
  margin: 0;
}

#reserve-wrapper .calendar-wrapper .calendar-viewport h2 {
  margin: 0;
  background: none;
  border: none;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
}

#reserve-wrapper .calendar-wrapper td.calendar-day-head {
  font-size: 12px;
}

#reserve-wrapper .calendar-wrapper td.calendar-day,
#reserve-wrapper .calendar-wrapper td.calendar-day .cell-wrapper {
  width: 35px;
  height: 35px;
}

#reserve-wrapper .calendar-wrapper td.calendar-day .cell-wrapper:after {
  border: none;
}

.no-touchevents #reserve-wrapper .calendar-wrapper td.calendar-day:hover,
.no-touchevents #reserve-wrapper .calendar-wrapper td.calendar-day.hover {
  z-index: 5;
}

#reserve-wrapper .calendar-wrapper td.calendar-day.hover {
  background-color: #651063;
  transition: background-color 250ms ease-out, transform 250ms ease-out;
}

.no-touchevents #reserve-wrapper .calendar-wrapper td.calendar-day.hover {
  transform: scale(0.8);
}

#reserve-wrapper .calendar-wrapper td.calendar-day.hover.delay-1 {
  transition-delay: 50ms;
}

#reserve-wrapper .calendar-wrapper td.calendar-day.hover.delay-2 {
  transition-delay: 100ms;
}

#reserve-wrapper .calendar-wrapper td.calendar-day.hover.delay-3 {
  transition-delay: 150ms;
}

#reserve-wrapper .calendar-wrapper td.calendar-day.hover.delay-4 {
  transition-delay: 200ms;
}

#reserve-wrapper .calendar-wrapper td.calendar-day.hover.delay-5 {
  transition-delay: 250ms;
}

#reserve-wrapper .calendar-wrapper td.calendar-day.hover.delay-6 {
  transition-delay: 300ms;
}

#reserve-wrapper .calendar-wrapper td.calendar-day.hover.delay-7 {
  transition-delay: 350ms;
}

#reserve-wrapper .calendar-wrapper td.calendar-day.hover.delay-8 {
  transition-delay: 400ms;
}

#reserve-wrapper .calendar-wrapper td.calendar-day.hover.delay-9 {
  transition-delay: 450ms;
}

#reserve-wrapper .calendar-wrapper td.calendar-day.hover.delay-10 {
  transition-delay: 550ms;
}

#reserve-wrapper .calendar-wrapper td.calendar-day.hover.delay-11 {
  transition-delay: 600ms;
}

#reserve-wrapper .calendar-wrapper td.calendar-day.hover.delay-12 {
  transition-delay: 650ms;
}

#reserve-wrapper .calendar-wrapper td.calendar-day.hover.delay-13 {
  transition-delay: 700ms;
}

#reserve-wrapper .calendar-wrapper td.calendar-day-np {
  background: none;
}

#body .hours {
  float: left;
  width: 250px;
  background: #F5F5F5;
  padding: 16px;
  margin: 5px 16px 0 0;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}

.ui-mobile #body .hours {
  float: none;
  clear: both;
  width: auto;
}

#body .hours p {
  margin: 0;
  padding: 16px;
  text-align: center;
  border-bottom: 1px dashed #88898B;
}

#body .hours p:last-child {
  border-bottom: none;
}

#body .hours .title {
  display: block;
  position: relative;
  border-bottom: 1px solid #88898B;
  text-align: center;
  padding: 0 0 16px 0;
  font-size: 14px;
  font-family: TitleFont, serif;
}

#body .hours p + .title {
  margin-top: 32px;
}

.text-increase #body .hours:before {
  font-size: 18px;
}


#body .call-to-action {
  position: relative;
  top: -16px;
  margin: 0px -16px 0px -16px;
}

.ui-widescreen #body .call-to-action {
  top: -32px;
  margin: 0px -32px 0px -32px;
}

.full-width #body .call-to-action {
  width: 951px;
  /*left	: 0;*/
}

.ui-mobile #body .call-to-action {
  /*
	left			: -5%;
	width			: 110%;
	*/
  top: -15px;
  margin-bottom: -5px;
}

#body .call-to-action .links {
  padding: 0 0 0 4px;
  background: #e5d8c3; /* Old browsers */
  background: -moz-linear-gradient(top, #e5d8c3 0%, #e6c573 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5d8c3), color-stop(100%, #e6c573)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #e5d8c3 0%, #e6c573 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #e5d8c3 0%, #e6c573 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #e5d8c3 0%, #e6c573 100%); /* IE10+ */
  background: linear-gradient(to bottom, #e5d8c3 0%, #e6c573 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e5d8c3', endColorstr='#e6c573', GradientType=0); /* IE6-9 */

  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.75);
}

#body .call-to-action .info {
  background: #E0E0E0;
  font-size: 12px;
  text-align: center;
  padding: 5px 0;

  box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
}

.no-boxshadow #body .call-to-action .info {
  border-top: 1px solid #000;
}

.text-increase #body .call-to-action .info {
  font-size: 16px;
}

#body .call-to-action .info .generic-btn {
  margin: 5px;
}

#body .call-to-action .info.light {
  background: #ECECEC;
}

#body .call-to-action .info.show-info {
  padding: 10px 0;
}

#body .call-to-action .info.passport {
  color: #000;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e0e0e0+0,f5f5f5+100 */
  background: rgb(224, 224, 224); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(245, 245, 245, 1) 0%, rgba(224, 224, 224, 1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(245, 245, 245, 1) 0%, rgba(224, 224, 224, 1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(245, 245, 245, 1) 0%, rgba(224, 224, 224, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5f5f5', endColorstr='#e0e0e0', GradientType=0); /* IE6-9 */
}

#body .call-to-action .info span {
  margin-right: 15px;
  white-space: nowrap;
}

#body .call-to-action .links .generic-btn {
  border: none;
  margin: 0 0 0 -3px;
  padding: 5px 20px;
  border-right: 1px solid #3f403f;
}

#body .call-to-action .generic-btn,
.generic-btn.small {
  padding: 3px 6px;
  margin: 12px 0 0 12px;
  line-height: 20px;
  font-size: 12px;
}

.text-increase .call-to-action .generic-btn,
#body.text-increast .generic-btn.small {
  font-size: 16px;
}


/*
#comments {
	position	: fixed;
	top			: 50%;
	left		: -250px;
	width		: 250px;
	height		: 200px;
	background	: #FBF9EE;
	z-index		: 1000;
	margin-top	: -100px;

	box-shadow			: 20px 0 40px #000;
}
#comments p {
	background	: #E8CF8C;
	padding		: 5px 20px;
}
#comments textarea {
	width	: 240px;
	height	: 100px;
}
#comments .more {
	position	: absolute;
	top			: 0;
	bottom		: 0;
	right		: -25px;
	width		: 25px;
	background	: red url(/library/images/global/leave-comment.png) 0 0 no-repeat;

	border-bottom-right-radius	: 5px;
	border-top-right-radius		: 5px;
}
*/


/*text size button*/
.text-size-btn {
  float: right;
  z-index: 1;
}


/*Rooms Section Specific CSS*/
#reserve-form-date,
#reserve-form-nights {
  width: 65px;
  background: #FBF9EE;
  border: 1px solid #E8CF8C;
  text-align: center;
  padding: 10px;
}

#reserve-form-date {
  width: 185px;
}

.reserve-form label {
  padding: 10px;
}

.ui-dialog.resnet-dialog .ui-dialog-content {
  overflow: hidden;
  padding: 0;
}


.shows-print-only {
  display: none;
}

#reserve-wrapper .showgate .reserve-calendar-next {
  display: none;
}


#showframe {
  width: 100%;
  height: 1000px;
  min-height: 100%;
  background: #FFF;
}

#show-wrapper {
  position: relative;
  top: -15px;
  margin: 0 -25px -58px -25px;
}


/*Area Highlight Styles*/
.area-highlight {
  margin-top: 35px;
  padding: 10px;
  border: 2px solid #651063;
  background: transparent url(/library/images/global/list-bg.png) bottom right no-repeat;
  background-size: 100% auto;
}

.area-highlight p {
  color: #651063;
}

.area-highlight .cta-link {
  float: left;
  margin: 10px 10px 10px 0;
}


/*Room Details and Amenities*/
#body .room-details {
  position: relative;
  background-color: #F5F5F5;
  width: 100%;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  font-size: 1.2em;
  color: #6F6F6F;
}

#body .reg-list .room-details {
  width: auto;
  background-color: #FFF;
  box-shadow: none;
  color: #6F6F6F;
}

#body .room-details strong {
  font-size: 1.5em;
}

#body .room-details td {
  width: 25%;
  padding: 32px 0;
  text-align: center;
}

#body .reg-list .room-details td {
  padding: 12px 6px;
}

.ui-mobile #body .room-details td {
  display: inline-block;
  *display: inline;
  zoom: 1;
  width: 50%;
}

#body .reg-list .room-details td:first-child {
  display: none;
}

#body .room-amenities h3 {
  position: relative;
  text-align: center;
  margin-bottom: 14px;
  font-family: BodyFont, sans-serif;
}

#body .reg-list .room-amenities h3 {
  color: #6F6F6F;
  text-align: left;
}

#body .room-amenities h3:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 25%;
  width: 50%;
  height: 1px;
  background: #000;
  z-index: -1;
}

.ui-mobile #body .room-amenities h3:before {
  left: 5%;
  width: 90%;
}

#body .reg-list .room-amenities h3:before {
  display: none;
}

#body .room-amenities h3 span {
  padding: 0 10px;
  background: #FFF;
}

#body .room-amenities ul {
  margin: 0;
  display: inline-block;
  *display: inline;
  zoom: 1;
  width: 32%;
}

.ui-mobile #body .room-amenities ul {
  width: 100%;
}

#body .reg-list .room-amenities ul {
  list-style: none;
}


.package-box {
  display: inline-block;
  width: 33%;
  padding: 10px 20px;
  vertical-align: top;
}

.ui-tablet .package-box {
  width: 49%;
}

.ui-mobile .package-box {
  width: 100%;
}

#body .package-box > * {
  max-width: 216px;
  margin: 10px auto;
  text-align: center;
}

.package-box img {
  width: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}


/*LOADING SPINNER*/
#overlay {
  position: fixed;
  z-index: 35;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  visibility: hidden;
  opacity: 0;
  transition: opacity 250ms ease-in, visibility 1ms linear 250ms, background-color 250ms ease-in 250ms;
}

.show-overlay #overlay {
  visibility: visible;
  opacity: 1;
  transition: opacity 250ms ease-out, visibility 1ms linear, background-color 250ms ease-out;
}

.clear-overlay #overlay {
  background-color: rgba(0, 0, 0, 0);
  transition: opacity 250ms ease-out, visibility 1ms linear, background-color 1ms linear;
}

#overlay .loader {
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -50px 0 0 -50px;
  width: 100px;
  height: 100px;
  opacity: 0;
  transition: opacity 250ms ease-in, visibility 1ms linear 250ms;
}

.show-loading-spinner #overlay .loader {
  visibility: visible;
  opacity: 1;
  transition: opacity 250ms ease-out, visibility 1ms linear;
}

#overlay .circular {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform-origin: center center;
  animation: loader-rotate 2000ms linear infinite;
}

#overlay .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: loader-dash 1500ms ease-in-out infinite, loader-color 6000ms ease-in-out infinite;
}

@keyframes loader-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes loader-color {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}


/*Dialog Rules*/
.dialog.confirm {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 24px 0 0 0;
  opacity: 0;
  visibility: hidden;
  border-radius: 2px;
  background-color: #FFF;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.54);
  max-width: 400px;
  font-size: 1.2em;
  will-change: transform, opacity, visibility;
  z-index: 50;
  transform: translateY(10px);
  transition: transform 250ms ease-in, opacity 250ms ease-in, visibility 1ms linear 250ms;
}

.ui-mobile .dialog.confirm {
  left: 2.5%;
  width: 95%;
  margin-left: 0 !important;
}

.dialog.confirm.active {
  visibility: visible;
  opacity: 1;
  transform: none;
  transition: transform 250ms ease-out, opacity 250ms ease-out, visibility 1ms linear;
}

.dialog.confirm h3 {
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  padding: 0 24px 20px 24px;
}

.dialog.confirm p {
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  padding: 0 24px 24px 24px;
}

.dialog.confirm .actions {
  padding: 8px 8px 8px 24px;
}

.dialog.confirm .actions a {
  outline: none;
  height: 36px;
  margin-left: 8px;
  padding: 0 8px;
  line-height: 36px;
  vertical-align: middle;
  text-decoration: none;
  color: #651063;
  text-transform: uppercase;
  float: right;
  background-color: #FFF;
  min-width: 64px;
  max-width: 66%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  will-change: background-color;
  transition: background-color 250ms ease-in;
}

.no-touchevents .dialog.confirm .actions a:hover,
.no-touchevents .dialog.confirm .actions a:focus {
  background-color: #EEE;
  transition: background-color 250ms ease-out;
}

.no-touchevents .dialog.confirm .actions a:active {
  background-color: #CCC;
  transition: background-color 250ms ease-out;
}


/*NOTIFICATIONS*/
#notifications {
  position: fixed;
  z-index: 40;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  transition: padding-bottom 250ms ease-out, transform 250ms ease-out;
}

#notifications.show {
  transform: none;
}

.footer-fixed #notifications {
  padding-bottom: 50px;
}

#notifications table,
#notifications tr,
#notifications td {
  border: none;
}

.no-touchevents #notifications table tbody tr:hover {
  background-color: transparent;
}

#notifications table td {
  color: inherit;
  padding: 0;
}

#notifications .viewport {
  text-align: center;
  font-size: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  transition: padding-bottom 250ms ease-in-out;
}

.footer-showing #notifications .viewport {
  padding-bottom: 64px;
}

#notifications .notification {
  position: relative;
  max-width: 100%;
  height: 0px;
  padding: 0px;
  color: #323232;
  font-size: 1.0rem;
  text-align: center;
  border-radius: 2px;
  display: none;
  background-color: #FFF;
  z-index: 10;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.54);
  transition: color 250ms ease-in-out;
}

.ui-mobile #notifications .notification {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.old-ie #notifications .notification {
  font-size: 14px;
}

.notification .action-cell {
  padding-left: 36px;
  white-space: nowrap;
}

#notifications .notification.active {
  height: auto;
  padding: 6px 24px;
  display: inline-block;
  color: #000;
  z-index: 20;
  transition: color 500ms ease-in-out;
}

#notifications .notification .action {
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  color: #d42128;
  float: right;
  padding: 8px;
}

#notifications .notification .material-icons {
  display: none;
  vertical-align: bottom;
  margin-bottom: -2px;
  text-decoration: none;
}

#notifications .count {
  position: absolute;
  bottom: 100%;
  right: 100%;
  margin: 0 -12px -12px 0;
  width: 24px;
  height: 24px;
  line-height: 24px;
  vertical-align: middle;
  text-align: center;
  font-size: 0.8em;
  background-color: #FFF;
  border-radius: 50%;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.35);
  opacity: 0;
}

.old-ie #notifications .count {
  font-size: 10px;
}

#notifications .count.show {
  opacity: 1;
}

#notifications .count.bounce {
  animation: notification-count-bounce 500ms forwards ease-out;
}

@keyframes notification-count-bounce {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  33% {
    opacity: 1;
    transform: scale(1.25);
  }
  66% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}


/*HOMEPAGE FEATURED LINKS*/
.right-side-content {
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
}

.right-side-content .body {
  position: absolute;
  right: 10px;
  top: 100px;
  width: 336px;
  padding: 2em;
  background: #FFF;
  background: rgba(255, 255, 255, 0.85);
  z-index: 20;
  text-align: center;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.35);
  border-radius: 2px;
  max-height: calc(100vh - 200px);
  overflow: hidden;
}

.ui-mobile .right-side-content .body {
  position: relative;
  right: auto;
  width: auto;
  top: auto;
  margin: 48px 0 0 0;
  padding: 16px;
  background: transparent;
  box-shadow: none;
  max-height: none;
  overflow: initial;
}

.featured-events .event:before,
.featured-events .event:after {
  content: '';
  position: absolute;
  z-index: -20;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #FFF;
}

.featured-events .event:before {
  background: transparent;
}

.featured-events .event:after {
  background: rgba(0, 0, 0, 0.05);
  z-index: -10;
  opacity: 0;
  will-change: opacity, transform;
  transform: scale(0, 1);
  transition: transform 250ms ease-in, opacity 250ms ease-in;
}

.no-touchevents .featured-events .event:hover:after {
  opacity: 1;
  transform: none;
  transition: transform 250ms ease-out, opacity 250ms ease-out;
}

/*homepage featured events*/
.featured-events {
  position: relative;
}

.featured-events .main-title {
  position: relative;
  display: block;
  padding: 0.25em;
  text-align: center;
  background: #FFF;
  text-decoration: none;
}

.featured-events .event {
  position: relative;
  display: block;
  text-decoration: none;
  padding: 0.5em 0;
  text-align: left;
  border-top: 2px solid #d1d2d4;
}

.featured-events .event:hover {
  color: currentColor;
}

.featured-events .event .date {
  display: inline-block;
  width: 24%;
  margin-right: 4%;
  margin-left: 2%;
  vertical-align: middle;
}

.featured-events .event .date.img {
  height: 90px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-size: cover;
}

.featured-events .event .date span {
  padding: 0.25em;
  display: block;
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
}

.featured-events .event .date .dow {
  background: rgba(255, 255, 255, 0.5);
  padding-top: 0;
}

.featured-events .event .date .day {
  background: rgba(255, 255, 255, 0.5);
  font-size: 2em;
  padding-bottom: 0;
}

.featured-events .event .title {
  display: inline-block;
  font-size: 1.3em;
  vertical-align: middle;
  margin: 0 4%;
}

.featured-events .event .date ~ .title {
  width: 70%;
  margin: 0;
}

.featured-events .event .subtitle {
  display: block;
  font-size: 0.7em;
  color: rgba(0, 0, 0, 0.54);
  margin-top: 0.35em;
}

.grecaptcha-badge {
  visibility: hidden;
}

/*Zachariahs fix for Wendover reservation calendar*/
.datepicker--nav-action svg {
  pointer-events: none;
}

/*Zachariah's OPC Header Nav Menu*/
.opc-menu {
  visibility: hidden;
  cursor: default;
  position: absolute;
  top: 100%;
  left: -14%;
  width: 210px;
  text-align: left;
  margin: 0 auto;
  padding: 10px 15px;
  box-shadow: 2px 2px 4px grey;
  border-radius: 4px;
  background: #fff;
  list-style-type: none;
  opacity: 0;
}

.ui-mobile .opc-menu {
  left: initial;
}

.opc-btn.logged-in:hover .opc-menu,
.ui-mobile .opc-btn.logged-in .opc-menu {
  visibility: visible;
  opacity: 1;
}

.ui-mobile .opc-btn.logged-in .opc-menu {
  position: relative;
  box-shadow: none;
}

.opc-btn-span {
  position: relative;
  display: inline-block;
  vertical-align: top;
  color: #6f6f6f;
  margin-left: 16px;
  overflow: visible;
  text-align: left;
  cursor: pointer;
  right: 7%;
}

.opc-menu a {
  padding: 0.5em;
  display: block;
}

.ui-mobile .opc-menu a {
  text-align: center;
}

.opc-btn a {
  text-decoration: none;
}